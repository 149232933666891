.geo-picker {
  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: theme-color('primary', 300);

    transform-origin: 0 0;
    animation: 2s geo-picker__loading both ease-out;
    @keyframes geo-picker__loading {
      from { transform: scaleX(0); }
      to { transform: scaleX(.8); }
    }
  }


  &__query-wrapper {
    display: flex;
    flex-direction: column;
    @include margin(-2, right);
    @include margin(-2, bottom);
    > * {
      @include margin(2, right);
      @include margin(2, bottom);
    }

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }


  $result-padding-x: 10;
  $result-padding-y: 7;

  &__result {
    text-decoration: none;
    @include text-default;

    position: relative;
    @include padding($result-padding-y, y);
    @include padding($result-padding-x, x);
    @include margin(-$result-padding-x, x);
    margin-bottom: -2px;
    @include border-round-lg;

    &:not(:first-child)::after {
      content: '';
      position: absolute;
      top: 0;
      left: spacer($result-padding-x);
      right: spacer($result-padding-x);
      height: 1px;
      background: $color-border;
      transition: background $transition-duration-fast;
    }

    box-shadow: inset 0 0 0 2px transparent;
    @include clickable-transition;
    transition-timing-function: linear;

    @media (hover: hover) {
      &:hover,
      &:focus {
        box-shadow: inset 0 0 0 2px theme-color('primary', 400);
        &,
        + * {
          &::after {
            background: none;
          }
        }
      }
    }

    &:active,
    &.active {
      background: $btn-hover-color;
    }

    display: flex;
    align-items: center;
  }


  $distance-size: 56px;
  $distance-font-size: 24px;
  $distance-font-size-sm: 18px;
  $distance-font-size-xs: 15px;
  $distance-unit-font-size: 12px;

  &__result-distance-wrapper {
    width: $distance-size;
    height: $distance-size;
    border-radius: calc($distance-size / 2);
    line-height: $distance-font-size;
    @include margin(8, right);

    background: theme-color('neutral', 200);
    @include text-theme-color('teal', 400);
    font-weight: bold;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__result-distance {
    font-size: $distance-font-size;

    &--sm {
      font-size: $distance-font-size-sm;
    }
    &--xs {
      font-size: $distance-font-size-xs;
    }
  }

  &__result-distance-unit {
    font-size: $distance-unit-font-size;
    line-height: $distance-unit-font-size;
    font-weight: normal;
  }


  &__result-main {
    flex: 1;
  }

  &__result-name {
    font-weight: bold;
    @include margin(1, bottom);
  }
}
