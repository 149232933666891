wizard {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.wizard {
  &__sidebar {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }

    flex: 2;

    background-color: theme-color('neutral', 200);

    @include padding(4, y);
    @include padding(8, x);
  }


  &__sidebar-footer {
    @include margin(20, top);
  }


  &__main {
    display: flex;
    flex-direction: column;
    flex: 5;
  }


  &__container {
    @include padding(8, x);
  }


  &__header {
    display: flex;
    flex-direction: column;
    @include padding(4, top);

    @include media-breakpoint-down(sm) {
      @include padding(4, bottom);
      @include border($pos: bottom);
    }
  }


  &__step {
    @include padding(4, top);
    @include padding(10, bottom);
    min-height: 0;
    overflow: auto;
  }


  &__footer {
    @include padding(4, y);
    @include border($pos: top);
    margin-top: auto;
  }
}



.progress-tracker {
  // Create a stacking context
  position: relative;
  z-index: 0;

  &__step {
    display: flex;
    align-items: center;
    height: 60px;
  }


  &__bullet {
    position: relative;

    width: 12px;
    height: 12px;
    border-radius: 6px;

    &,
    &::before {
      background: theme-color('neutral', 400);
    }

    // Track from the previous bullet to the current one
    &::before {
      position: absolute;
      z-index: -1;
      content: '';

      top: -54px;
      left: 5px;
      width: 2px;
      height: 60px;
    }


    &--completed,
    &--in-progress {
      &,
      &::before {
        background: theme-color('primary', 300);
      }

      &::before {
        left: 4px;
        width: 4px;
      }
    }


    &--in-progress {
      background: theme-color('primary', 400);
      box-shadow: 0 0 0 6px theme-color('primary', 300, .5);

      // White ball inside the bullet
      &::after {
        position: absolute;
        content: '';

        top: 3px;
        left: 3px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: white;
      }
    }
  }


  &__step:first-child > &__bullet::before {
    display: none;
  }


  &__name {
    @include margin(4, left);

    &--in-progress {
      font-weight: bold;
    }
  }
}
