@mixin action-footer {
  margin-top: auto;

  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @include border(theme-color(neutral, 300), $pos: top);
  @include bg-theme-color('neutral', 100);

  display: flex;

  @include padding(4, y);
  // Use the same horizontal padding as the .container class
  @each $breakpoint, $padding in $container-paddings {
    @include media-breakpoint-up($breakpoint) {
      @include padding($padding, x);
    }
  }

  @include if-container {
    @include padding(0, x);
  }
  @each $breakpoint, $padding in $container-paddings {
    @include container-breakpoint-up($breakpoint) {
      @include padding($padding, x);
    }
  }
}


@mixin action-footer-button-row {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  @include margin(-2, right);
  > * {
    flex: auto;
    @include margin(2, right);
    @include media-breakpoint-up(md) {
      flex: none;
    }
  }
}
