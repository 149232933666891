$tool-color-size: 22px;

.tools {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item,
  &__divider {
    @include margin(1, bottom);
  }


  &__item {
    @include btn($shape: round);

    position: relative;

    border: 0;
    border-radius: 0;
    width: 100%;

    &:not(:disabled, .disabled).active {
      background: theme-color('primary', 300);
      @include text-inverted;
    }
  }

  &__item-border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;

    @include clickable-transition;
  }


  &__divider {
    flex: 0 0 1px;
    align-self: stretch;
    @include margin(2, x);
    background: $color-border;
  }


  &__dropdown {
    display: flex;
    flex-direction: column;

    @include margin(-2, bottom);
    > * {
      @include margin(2, bottom);
    }
  }


  &__row {
    display: flex;
    justify-content: center;

    @include margin(-1, right);
    > * {
      @include margin(1, right);
    }
  }


  &__color {
    width: $tool-color-size;
    height: $tool-color-size;
    border-radius: calc($tool-color-size / 2);
  }

  &__line-size {
    background: $color-text;
  }

  &__symbols-dropdown {
    @include margin(-1, right);
    max-width: 5 * (btn-size(square) + spacer(1));
  }

  &__symbol-item {
    text-transform: initial;
  }

  &__triangle {
    $triangle-height: 8px;
    $triangle-width: 4px;

    position: absolute;
    right: 2px;
    top: calc(50% - #{calc($triangle-height / 2)});

    border-style: solid;
    border-width: calc($triangle-height / 2) 0 calc($triangle-height / 2) $triangle-width;
  }

  &__number-input {
    text-align: center;
    padding: 0;
    width: 42px;
  }
}
