@use 'sass:color';


// These variables should always be kept up to date with the first lines of variables.js.
// This is not very ideal, but it's only a few variables, and there's no standardized solution to
// sharing variables between sass and javascript yet.

$tile-border-width: 1px;  // Border around the entire tile
$content-header-height: 47px;  // Including bottom border
$content-header-height-inactive: 36px;
$whiteboard-tools-width: $content-header-height;
$whiteboard-eraser-size: 20px;

$shared-pointer-click-duration: 1200ms;


// These variables should not be synced


// Colors

$color-logo: #c0cacd;
$color-logo-footer: #9daaae;

$color-user-tile-background: theme-color('teal', 400);
$color-user-tile-avatar: $color-logo;
$color-user-tile-avatar-background: $color-page;

$color-drop-file-overlay: rgba(white, .7);

$color-whiteboard-bg: rgba(#2a505a, .1);
$color-whiteboard-scrollbar-track: rgba(black, .1);
$color-whiteboard-scrollbar-thumb: rgba(black, .3);
$color-whiteboard-scrollbar-thumb-hover: rgba(black, .4);
$color-whiteboard-scrollbar-thumb-active: rgba(black, .45);



$z-contexts: (
  meeting: (modal, permission-arrow, raise-hand-overlay),
  app: (tutorial-arrow, tiles, content-buttons, chat-bubbles, drop-file),
  app-phone: (tiles, header-footer),
  tiles: (active-tile, inactive-tile, pointers, knocker-tile, tooltip),
  tile: (whiteboard-tutorial, stats, overlay, chrome),
  whiteboard-body: (document, window, paper, eraser, download-progress, scrollbar, rendering),
);
