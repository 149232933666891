$knocker-tile-transition-duration: 300ms;

knocker-tile {
  position: absolute;
  z-index: z(tiles, knocker-tile);
  pointer-events: none;
}


.knocker-tile {
  left: auto;
  right: 0;
  pointer-events: initial;
  transition: width $knocker-tile-transition-duration;

  &__footer {
    @extend .user-tile__footer;
    &.ng-hide {
      opacity: 0;
    }
    &.ng-hide-add,
    &.ng-hide-remove {
      transition: opacity $knocker-tile-transition-duration;
    }
  }


  &__text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    @include padding(2);
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }


  &__body {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;

    background: $color-surface;

    overflow: hidden;
  }


  &__video-wrapper {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 12px;
      pointer-events: none;

      background: linear-gradient(to right, scrim-gradient(white));
      transition: opacity $knocker-tile-transition-duration;

      .knocker-tile--compact & {
        opacity: 0;
      }
    }
  }

  &__video {
    @extend .user-tile__video;
    background: $color-surface !important;
  }

  &__toggle-compact-btn {
    transition-property: transform, $transition-property-clickable;
    .knocker-tile--compact & {
      transform: rotate(180deg);
    }
  }

  &__overlay-btns {
    position: absolute;
    left: spacer(1);
    bottom: 0;
    display: flex;

    > * {
      @include margin(2, right);
    }

    &.ng-hide-add,
    &.ng-hide-remove {
      transition: opacity $knocker-tile-transition-duration;
    }
    &.ng-hide {
      opacity: 0;
    }
  }


  &__unread-badge {
    @extend .main-chat__unread-badge;

    &--phone {
      @extend .main-chat__unread-badge--phone;
    }
  }
}



.knocker-modal {
  display: flex;
  flex-direction: column;
  min-height: 0;

  @include margin(-5, bottom);
  @include margin(-5, right);
  > * {
    @include margin(5, bottom);
    @include margin(5, right);
  }


  &__video-wrapper {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  &__video {
    @include border-round;

    object-fit: cover;
    max-width: 200px;
    max-height: 200px;
  }


  &__chat {
    display: flex;
    flex-direction: column;
    height: 200px;
    @include border;
    @include border-round;
    @include p;
  }


  @media (orientation: landscape) and (min-width: 540px) {
    flex-direction: row;
  }
}
