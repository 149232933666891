@use 'sass:map';


.card {
  @include -card-base;
  &.clickable {
    @include -card-clickable;
  }

  @include -card-size(md);
  @each $size in map.keys($card-sizes) {
    &--#{$size} {
      @include -card-size($size);
    }
  }

  &--no-padding {
    @include -card-no-padding;
  }
}
