@import './help/index';
@import './navigation/index';
@import './onboarding/index';


.dashboard {
  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: theme-color('primary', 300);

    transform-origin: 0 0;
    animation: 2s dashboard__loading both ease-out;
    @keyframes dashboard__loading {
      from { transform: scaleX(0); }
      to { transform: scaleX(.8); }
    }
  }
}
