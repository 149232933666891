$device-icon-size: 70px;
$device-icon-size-phone: 35px;
$greet-container-padding: 8;
$greet-container-padding-phone: 4;



.greet {
  position: fixed;
  top: 0;
  bottom: 0;  // Don't use "height: 100%": SEOquake extension breaks this
  left: 0;
  right: 0;
  background: $color-page;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;


  &__border {
    @extend .app__border;
  }


  &__banner {
    @include padding(5, y);
    background: theme-color('warning', 100);
    text-align: center;
  }

  &__background {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }


  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;

    padding:
      var(--safe-area-inset-top)
      var(--safe-area-inset-left)
      var(--safe-area-inset-right)
      var(--safe-area-inset-bottom);
  }


  &__header {
    display: flex;
    justify-content: flex-end;
    @include padding(2);

    @include media-phone {
      // align padding with greet__main
      @include padding($greet-container-padding-phone);
    }
  }


  &__main {
    flex: 0 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: auto;

    @include padding($greet-container-padding-phone, x);
    @include media-desktop {
      @include padding($greet-container-padding);
      align-self: flex-start;
    }

    @include media-desktop {
      margin-top: auto;
      min-width: 400px;
      max-width: 500px;
      @media (max-width: 600px) {
        max-width: 100vw;
      }
    }
  }


  &__box {
    background: $color-surface;
    border-radius: $border-radius-lg;
    @include shadow(16);
    @include padding(8);
    @include margin(6, bottom);
    align-self: stretch;
  }

  &__box-custom-logo {
    display: flex;
    justify-content: center;

    @include padding(6, bottom);
    border-bottom: 1px solid $color-border;
    @include margin(6, bottom);
  }

  &__devices {
    display: flex;
    align-items: stretch;
  }

  &__device {
    flex: 1 0 0;
    min-width: 0;

    &:not(.greet__device--phone) {
      flex-direction: column;
      align-items: center;
      height: auto;
      @include padding(6);
    }

    &--phone {
      display: flex;
    }
  }

  &__device-icon {
    width: $device-icon-size;
    height: $device-icon-size;
    @include margin(6, bottom);
    stroke: theme-color('teal', 500);

    &--phone {
      height: $device-icon-size-phone;
      width: auto;
      @include margin(0, bottom);
      @include margin(4, left);
      stroke: theme-color('neutral', 100);

      > svg {
        width: auto;  // Workaround for Safari
      }
    }
  }

  &__device-separator {
    position: relative;
    display: flex;
    align-items: center;
    @include padding(6);
    text-transform: uppercase;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      width: 1px;
      background: $color-border;
    }

    &::before {
      top: 0;
      bottom: calc(50% + 1.4em);
    }
    &::after {
      top: calc(50% + 1.4em);
      bottom: 0;
    }
  }


  &__video-container {
    @extend .settings__test-video-container;
  }
  &__video {
    @extend .settings__test-video;
  }


  &__chat {
    display: flex;
    flex-direction: column;
    height: 200px;
    border: 1px solid $color-border;
    border-radius: $border-radius;
  }


  &__loading {
    @include margin(2, left);
    @include margin(-2, right);
  }

  &__ended-header-image {
    @include icon(48px);
  }
}
