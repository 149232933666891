@use 'sass:map';


$icon-sizes: (
  sm: 14px,
  md: 24px,
  lg: 32px,
  xl: 40px,
);

@function icon-size($size: md) {
  @return map.get($icon-sizes, $size);
}


@mixin -icon-base {
  flex: none;
  display: flex;
  line-height: 0;
}

@mixin -icon-color($color: null) {
  @if $color {
    @include set-icon-color($color);
  }
  @include use-icon-color;
}

@mixin -icon-size($size: md) {
  $size-px: $size;
  @if map.has-key($icon-sizes, $size) {
    $size-px: icon-size($size-px);
  }

  @if $size-px {
    width: $size-px;
    height: $size-px;
    svg {
      width: $size-px;
      height: $size-px;
    }
  }
}

@mixin icon($size: md, $color: null) {
  @include -icon-base;
  @include -icon-size($size);
  @include -icon-color($color);
}
