.cookie-notice {
  position: fixed;
  bottom: spacer(10);
  left: spacer(10);
  overflow: hidden;

  background: $color-surface;
  border-radius: $border-radius;
  border-left: 3px solid theme-color('primary', 300);
  @include shadow(4);

  &__inner {
    background: theme-color('primary', 100);
    @include padding(5);

    display: flex;
    flex-direction: column;
    align-items: stretch;

    max-width: 24em;
  }
}
