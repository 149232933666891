$range-thumb-size: 24px;
$range-track-height: 4px;


@mixin slider-thumb {
  &::-webkit-slider-thumb { @content; }
  &::-moz-range-thumb { @content; }
  &::-ms-thumb { @content; }
}


@mixin slider-track {
  &::-webkit-slider-runnable-track { @content; }
  &::-moz-range-track { @content; }
  &::-ms-track { @content; }
}
