$arrow-size: 6px;
$arrow-margin-right: 3;
$arrow-margin-left: 2;


@mixin select($size: md) {
  @include input($size);

  padding-right: spacer(3) + icon-size(sm) + spacer(3);
  cursor: default;
  user-select: none;

  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMy40NjUgNi4wODcgNyA5LjYyM2wzLjUzNi0zLjUzNkExIDEgMCAwIDAgOS4xMiA0LjY3M0w3IDYuNzk0IDQuODc4IDQuNjczYTEgMSAwIDEgMC0xLjQxNCAxLjQxNFoiIGZpbGw9IiM4MjgyOEMiLz48L3N2Zz4=');
  background-size: icon-size(sm) icon-size(sm);
  background-position: right spacer(3) center;
  background-repeat: no-repeat;
  overflow: hidden;

  white-space: nowrap;
  & > * {
    overflow: hidden;
  }

  &__placeholder {
    @include text-muted;
  }
}
