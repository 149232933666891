@import 'contentButtons/index';
@import 'footer/index';
@import 'header/index';
@import 'sidebar/index';


$border-height: 3px;


.app {
  position: fixed;
  top: 0;
  bottom: 0;  // Don't use "height: 100%": SEOquake extension breaks this
  left: 0;
  right: 0;
  overflow: hidden;
  background: $color-page;
  touch-action: none;

  @include media-desktop {
    min-width: 700px;
  }

  display: flex;
  flex-direction: column;


  &__border {
    height: $border-height;
    background: theme-color('primary', 300);
  }


  &__banner {
    @include bg-theme-color('primary', 300);
    @include padding(4, x);
    @include padding(2, y);
    @include margin(1, bottom);
    min-height: spacer(16);

    display: flex;
    justify-content: center;
    align-items: center;

    &--warning {
      @include bg-theme-color('warning', 100);
      @include text-default;
      border-bottom: 1px solid theme-color('warning', 200);
    }
  }


  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }


  &__main {
    flex: 1;
    display: flex;
    padding:
      var(--safe-area-inset-top)
      var(--safe-area-inset-left)
      var(--safe-area-inset-right)
      var(--safe-area-inset-bottom);
  }


  &__left {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    min-width: 0;
  }
  &__right {
    flex: 0 0 auto;
  }

  &__top {
    flex: 1 0 0;
    position: relative;
    display: flex;
  }

  &__bottom {
    @include padding(2);
  }

  &__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: $color-logo;
    pointer-events: none;
  }

  &__tiles {
    flex: 1 0 0;
    display: flex;
    pointer-events: none;
  }



  // Phone

  &--dark-background {
    background: theme-color('teal', 400);
  }

  &__phone-main {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    @include media-vertical-footer {
      flex-direction: row-reverse;
    }
  }


  &__phone-header {
    @include z(app-phone, header-footer);
    @include padding(2);
    pointer-events: none;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    &.ng-hide-animate {
      transition-property: transform, opacity;
    }
    &.ng-hide {
      display: block !important;
      opacity: 0;
      transform: translateY(-100%);
    }
    &.ng-hide-add {
      transition-duration: $transition-duration-slow;
    }
    &.ng-hide-remove {
      transition-duration: $transition-duration-medium;
    }

    @include media-vertical-footer {
      left: auto;
      bottom: 0;
      &.ng-hide {
        transform: translateX(100%);
      }
    }
  }


  &__phone-footer {
    @extend .app__phone-header;

    top: auto;
    bottom: 0;

    &.ng-hide {
      transform: translateY(100%);
    }

    @include media-vertical-footer {
      top: 0;
      left: 0;
      right: auto;
      &.ng-hide {
        transform: translateX(-100%);
      }
    }
  }


  &__phone-tiles {
    flex: 1 0 0;
    display: flex;
    @include z(app-phone, tiles);
  }
}
