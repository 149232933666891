.drop-file {
  position: absolute;
  z-index: z(app, drop-file);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: $color-drop-file-overlay;
  font-family: $font-family-heading;
  font-size: 40px;
}

.file-picker {
  &__iframe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &--hidden {
      display: none;
    }
  }
}


// Imitate the Google Drive button style
.drive-logout-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;

  button {
    position: absolute;
    transition: all .218s;
    box-shadow: none;
    background-color: #f5f5f5;
    // stylelint-disable-next-line value-no-vendor-prefix
    background-image: -webkit-linear-gradient(top, #f5f5f5, #f1f1f1);
    color: #444444;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 2px;
    cursor: default;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    margin-right: 20px;
    margin-bottom: 20px;
    bottom: 0;
    right: 0;
    height: 29px;
    line-height: 27px;
    outline: 0;
    padding: 0 8px;
    pointer-events: auto;

    &:hover {
      background-color: #f8f8f8;
      // stylelint-disable-next-line value-no-vendor-prefix
      background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
      border: 1px solid #c6c6c6;
      color: #333333;
    }

    &:active {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
      background: #f8f8f8;
      color: #333333;
    }
  }
}
