@use 'sass:map';


$input-heights: (
  sm: 30px,
  md: 36px,
);

@function input-height($size: md) {
  @return map.get($input-heights, $size);
}


$input-disabled-bg-color: theme-color('neutral', 200);
$input-disabled-text-color: $color-text-muted;
$input-box-shadow:
  inset 0 0 0 1px theme-color('neutral', 400),
  0 0 0 0 theme-color('neutral', 400);
$input-box-shadow-hover:
  inset 0 0 0 1px theme-color('neutral', 500),
  0 0 0 0 theme-color('neutral', 500);
$input-box-shadow-focus:
  inset 0 0 0 1px theme-color('neutral', 500),
  0 0 0 1px theme-color('neutral', 500);


// stylelint-disable selector-no-vendor-prefix
@mixin placeholder {
  &.placeholder { @content; }
  &::placeholder { @content; opacity: 1; }
  &:-moz-placeholder { @content; opacity: 1; }
  &::-moz-placeholder { @content; opacity: 1; }
  &::-webkit-input-placeholder { @content; }
}
// stylelint-enable selector-no-vendor-prefix


@mixin input-group-child {
  .input-group > & {
    // Ensure the border/outline of a hovered/focused input is shown above sibling elements.
    &:hover,
    &:focus {
      z-index: 1;
    }

    &:not(:first-child) {
      margin-left: -1px;
    }
  }
}


@mixin -input-disabled {
  // Do not use the `background` shorthand: this hides the small arrow in a disabled select
  background-color: $input-disabled-bg-color;
  color: $input-disabled-text-color;
  opacity: 1;
}

@mixin -input-not-disabled {
  &:hover {
    box-shadow: $input-box-shadow-hover;
  }
  &:focus {
    outline: none;
    box-shadow: $input-box-shadow-focus;
  }
}

@mixin -input-base {
  // Simlate the vertical centering of an `input` element on e.g. a `div.input` element.
  &:not(input) {
    display: flex;
    align-items: center;
  }

  height: var(--input-height);
  width: 100%;
  @include padding(2, x);
  border-radius: $border-radius;

  color: inherit;
  @include placeholder {
    @include text-muted;
  }

  background: theme-color('neutral', 100);
  border: 0;
  box-shadow: $input-box-shadow;
  transition: box-shadow $transition-duration-medium;

  // stylelint-disable property-no-vendor-prefix
  -webkit-appearance: none;
  -moz-appearance: none;
  // stylelint-enable property-no-vendor-prefix

  @include input-group-child;
}

@mixin -input-error {
  border: 1px solid theme-color('error', 400);
}

@mixin -input-size($size: md) {
  --input-height: #{input-height($size)};
}

@mixin -input-with-icon($with-icon: null) {
  @if $with-icon == left {
    padding-left: var(--input-height);
  } @else if $with-icon == right {
    padding-right: var(--input-height);
  } @else if $with-icon != null {
    @error 'Invalid input-icon position: ' + $with-icon;
  }
}

@mixin input($size: md, $with-icon: null) {
  @include -input-base;
  @include -input-size($size);
  @include -input-with-icon($with-icon);

  &:disabled,
  &.disabled,
  &[readonly] {
    @include -input-disabled;
  }

  &:not(:disabled, .disabled) {
    @include -input-not-disabled;
  }

  &.error {
    box-shadow: inset 0 0 0 1px theme-color('error', 300);
  }
}



@mixin -input-icon-base($pos: left) {
  position: absolute;
  pointer-events: none;
  top: calc((var(--input-height) - icon-size()) / 2);
  width: icon-size();
  height: icon-size();
  @include -icon-color(theme-color('neutral', 500));
}

@mixin -input-icon-size($size: md) {
  @include -input-size($size);
}

@mixin -input-icon-pos($pos: left) {
  @if $pos == left {
    left: calc((var(--input-height) - icon-size()) / 2);
  } @else if $pos == right {
    right: calc((var(--input-height) - icon-size()) / 2);
  } @else {
    @error 'Invalid input-icon position: ' + $pos;
  }
}

@mixin input-icon($pos: left, $size: md) {
  @include -input-icon-base($pos);
  @include -input-icon-size($size);
  @include -input-icon-pos($pos);
}



@mixin label {
  display: block;
  @include margin(1, bottom);
  @include text-base;
  // Make sure also empty labels take up vertical space.
  min-height: $line-height-base;
}



@mixin required {
  &::after {
    content: '*';
    @include text-theme-color('error', 400);
  }
}
