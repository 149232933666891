@use 'sass:map';


$tag-sizes: (
  sm: (
    tag: 18px,
    icon: 14px,
    text: 12px,
    padding: spacer(2),
  ),
  md: (
    tag: 24px,
    icon: 14px,
    text: 14px,
    padding: spacer(3),
  ),
  lg: (
    tag: 30px,
    icon: 14px,
    text: 16px,
    padding: spacer(4),
  ),
);

@function tag-size($property, $size: md) {
  @return map.get(map.get($tag-sizes, $size), $property);
}


@mixin -tag-base {
  display: inline-flex;
  align-items: center;

  font-size: var(--tag-font-size);
  font-weight: 500;

  height: var(--tag-size);
  min-width: var(--tag-size);
  padding-left: var(--tag-padding);
  padding-right: var(--tag-padding);
  border-radius: calc(var(--tag-size) / 2);

  background: theme-color('neutral', 500, .12);
  border: 1px solid theme-color('neutral', 500, .12);
  @include text-default;
}

@mixin -tag-size($size: md) {
  --tag-size: #{tag-size(tag, $size)};
  --tag-icon-size: #{tag-size(icon, $size)};
  --tag-font-size: #{tag-size(text, $size)};
  --tag-padding: #{tag-size(padding, $size)};
  --tag-padding-close: #{tag-size(padding-close, $size)};
}


@mixin -tag-text-base {
  flex: 1 1 0;
  min-width: 0;
  @include text-truncate;
}


@mixin -tag-close-base {
  @include icon($size: var(--tag-icon-size), $color: theme-color('neutral', 500));
  align-items: center;
  justify-content: center;

  margin-right: calc(-1 * var(--tag-padding));
  @include margin(1, left);
  width: var(--tag-size);
  height: var(--tag-size);
  border-radius: 50%;

  @include clickable-transition;
  @include not-disabled {
    cursor: pointer;

    &:hover,
    &:focus {
      background: theme-color('neutral', 500, .18);
    }
    &:active,
    &.active {
      box-shadow: $btn-active-shadow;
    }
  }
}



@mixin tag($size: md) {
  @include -tag-base;
  @include -tag-size($size);
}

@mixin tag-text {
  @include -tag-text-base;
}

@mixin tag-close {
  @include -tag-close-base;
}
