@import 'files/index';
@import 'tools/index';


$eraser-color: rgba(black, .2);


.whiteboard-tile {
  &:not(.tile--inactive, .tile--spectator, .tile--locked) {
    .tile__body,
    .tile__catch-events-overlay {
      left: $whiteboard-tools-width;
    }
  }


  &__rendering {
    position: absolute;
    z-index: z(whiteboard-body, rendering);
    left: 50%;
    top: 50%;
    height: 10%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    background: $color-overlay;
    @include text-inverted;
  }

  &__rendering-inner {
    height: 100%;
    padding: 10%;
  }


  &__download-progress {
    position: absolute;
    z-index: z(whiteboard-body, download-progress);
    left: 0;
    top: 0;
    height: 3px;
    background: theme-color('primary', 300);
    transition: $transition-duration-fast width;
  }


  &__menu-wrapper {
    @include margin(-1, left);
    width: $whiteboard-tools-width;
    height: $content-header-height;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  &__tools {
    position: absolute;
    top: $content-header-height;
    bottom: 0;
    left: 0;
    width: $whiteboard-tools-width;
    background: $color-surface;
    @include padding(1, top);

    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-right: 1px solid $color-border;
  }


  &__body {
    &--drag {
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  }


  &__document-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include text-muted;
  }


  &__document {
    z-index: z(whiteboard-body, document);
  }
  &__paper {
    z-index: z(whiteboard-body, paper);
  }


  &__document,
  &__paper {
    position: absolute;
    width: 100%;
  }


  &__image {
    position: absolute;
    width: 100%;
  }


  &__formula-wrapper {
    pointer-events: none;
  }


  &__canvas-wrapper {
    position: absolute;
  }


  &__window {
    position: absolute;
    z-index: z(whiteboard-body, window);
    pointer-events: none;
    @include border;
    @include border-round-sm;
    outline: 2000px solid $color-whiteboard-bg;
  }


  &__eraser {
    position: absolute;
    z-index: z(whiteboard-body, eraser);
    display: none;
    pointer-events: none;

    width: $whiteboard-eraser-size;
    height: $whiteboard-eraser-size;
    border-radius: calc($whiteboard-eraser-size / 2);
    border: 1px solid $eraser-color;
  }


  &__scrollbar {
    position: absolute;
    z-index: z(whiteboard-body, scrollbar);
    background: $color-whiteboard-scrollbar-track;
    border-radius: calc($scrollbar-width / 2);

    opacity: 0;
    transition: opacity $transition-duration-fast;

    // show the scrollbar when hovering over tile:
    // to select the scrollbar as a child of the tile ::hover element,
    // append the scrollbar selector to the tile ::hover element. This overwrites the
    // sass behaviour of automatically prepending the parent to a nested selector
    &:active,
    .whiteboard-tile__body:hover & {
      opacity: 1;
    }

    &--vertical {
      top: 0;
      bottom: $scrollbar-width;
      right: 0;
      width: $scrollbar-width;
    }

    &--horizontal {
      left: 0;
      right: $scrollbar-width;
      bottom: 0;
      height: $scrollbar-width;
    }
  }

  &__scrollbar-thumb {
    position: absolute;
    width: $scrollbar-width;
    height: $scrollbar-width;
    border-radius: calc($scrollbar-width / 2);

    background: $color-whiteboard-scrollbar-thumb;
    &:hover,
    &:focus {
      background: $color-whiteboard-scrollbar-thumb-hover;
    }
    &:active,
    &.active {
      background: $color-whiteboard-scrollbar-thumb-active;
    }
  }
}


// KEEP THIS IN SYNC WITH FormulaRenderer.js:SVG_TEMPLATE
// stylelint-disable-next-line selector-class-pattern
.mathTextbox {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  pointer-events: none;
  border: 0 !important;
  line-height: 1 !important;
}
.mq-root-block,
.mq-math-mode .mq-root-block {
  padding: .08em !important;
}
.mq-latex-command-input {
  border: 0 !important;
}
// END OF SYNCED PART


$color-tutorial-bg: rgba(#dcdcdc, .5);
$color-tutorial-field-bg: rgba(#d8dcdc, .85);


.whiteboard-tutorial {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z(tile, whiteboard-tutorial);

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 250px;
  @include padding(4, x);
  @include padding(4, bottom);
  &--collapsed {
    padding-top: 100px;
  }

  background: $color-tutorial-bg;
  overflow-y: auto;

  &.ng-enter,
  &.ng-leave {
    transition: opacity $transition-duration-medium;
  }
  &.ng-enter:not(.ng-enter-active),
  &.ng-leave.ng-leave-active {
    opacity: 0;
  }


  &__field {
    background: $color-tutorial-field-bg;
    border-radius: $border-radius-lg;
    @include padding(4);

    &--actions {
      position: absolute;
      top: 75px;
      left: 80px;
      width: 240px;
    }

    &--tools {
      position: absolute;
      top: 150px;
      left: 80px;
      width: 240px;
    }

    &--minimize {
      position: absolute;
      top: 60px;
      right: 87px;
      width: 160px;
      text-align: right;
    }
  }


  &__arrow {
    position: absolute;
    width: 60px;

    &--menu {
      top: 32px;
      left: 11px;
      transform: scaleY(-1) rotate(7deg);
    }

    &--actions {
      top: 40px;
      left: 35px;
      transform: scaleY(-1) rotate(-30deg);
    }

    &--pages {
      top: 11px;
      left: 245px;
      transform: rotate(95deg);
    }

    &--tools {
      left: 46px;
      top: 242px;
      transform: rotate(90deg) scaleX(-1);
    }

    &--minimize {
      top: 38px;
      right: 25px;
      transform: rotate(174deg);
    }
  }


  &__row {
    display: flex;
    @include margin(2, bottom);
  }

  &__image {
    flex: none;
    display: flex;
    width: 36px;
    height: 36px;
    @include margin(4, right);
  }

  &__title {
    @include text-h(h4);
    @include margin(1, bottom);
  }



  &__hint-wrapper {
    position: absolute;
    z-index: z(tile, whiteboard-tutorial);

    &.ng-enter {
      transition-property: opacity, transform;
      transition-duration: 300ms;
      transition-timing-function: linear, cubic-bezier(.2, .65, .66, 1.96);
    }
    &.ng-leave {
      transition-property: opacity, transform;
      transition-duration: 150ms;
    }
    &.ng-enter:not(.ng-enter-active),
    &.ng-leave.ng-leave-active {
      opacity: 0;
      transform: translateY(30px);
    }
  }

  &__hint {
    position: absolute;
    top: 65px;
    left: 80px;
    @include padding(4);
    @include shadow(2);

    border-radius: $border-radius;
    background: $color-surface;
    white-space: nowrap;
  }
}
