$dropdown-margin: 2;


dropdowns-deprecated {
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}


dropdown-deprecated {
  position: absolute;
  top: 0;
  left: 0;
}


.dropdown-deprecated {
  &__wrapper {
    &.ng-hide {
      opacity: 0;
      transform: translateY(-.5rem);
    }
    &.ng-hide-remove {
      display: block !important;
      transition-property: opacity, transform;
      transition-duration: $transition-duration-medium;
    }
  }


  pointer-events: all;
  background: $color-surface;
  border-radius: $border-radius;
  overflow: hidden;
  @include shadow(4);
  @include padding(2);
  max-width: calc(100vw - #{spacer(6)});

  &--horizontal:not(.dropdown-deprecated--no-margin) {
    @include margin($dropdown-margin, x);
  }
  &--vertical:not(.dropdown-deprecated--no-margin) {
    @include margin($dropdown-margin, y);
  }

  &--no-padding {
    @include padding(0);
  }
  &--no-padding-x {
    @include padding(0, x);
  }
  &--large-padding {
    @include padding(4);
  }


  &__list {
    margin: 0;
    padding: 0;
  }

  &__list-item {
    display: flex;
    padding: calc((btn-size(height) - $line-height-base) / 2) btn-size(padding-x);


    &--clickable {
      cursor: default;

      &.disabled,
      &:disabled {
        @include text-muted;
      }

      &:not(.disabled, :disabled) {
        @include text-default;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active,
        &.active {
          background: theme-color('neutral', 200);
        }

        &:active,
        &.active {
          background: theme-color('info', 100);
        }
      }


      @at-root a#{&} {
        text-decoration: none;
        color: inherit;
      }
    }
  }


  &__list-icon {
    @include icon;
    @include margin(3, right);
  }


  &__submenu {
    @include margin(1);
    border-radius: $border-radius;
    @include shadow(-1);
    background: theme-color('neutral', 200);

    display: none;
    &.active {
      display: block;
    }

    transition: height $transition-duration-medium;
    overflow: hidden;
  }


  &__divider {
    @include margin(2);
    height: 1px;
    background: $color-border;
  }
}
