@use 'sass:map';


$container-paddings-prev: ();
$padding-prev: null;
@each $breakpoint, $padding in $container-paddings {
  @if $padding-prev {
    $container-paddings-prev: map.merge($container-paddings-prev, ($breakpoint: $padding-prev));
  }
  $padding-prev: $padding;
}


.container {
  width: 100%;
  @include margin(auto, x);
  @include container(xl);

  @each $breakpoint, $padding in $container-paddings {
    @include media-breakpoint-only($breakpoint) {
      @include padding($padding, x);
    }
  }
  @each $breakpoint in map.keys($container-paddings-prev) {
    &--#{$breakpoint} {
      @include container($breakpoint);
    }
  }

  // If we have a containment context to size against: reset the media queries, and set container
  // queries instead.
  @container (min-width: 0) {
    padding: 0;
  }
  @each $breakpoint, $padding in $container-paddings {
    @include container-breakpoint-only($breakpoint) {
      @include padding($padding, x);
    }
  }
  @each $breakpoint in map.keys($container-paddings-prev) {
    &--#{$breakpoint} {
      @include container($breakpoint, 'container');
    }
  }

  &--collapse {
    width: auto;
  }

  &--fullwidth {
    max-width: 100%;
  }
}


.container-type-size { container-type: size !important; }
.container-type-inline-size { container-type: inline-size !important; }
.container-type-normal { container-type: normal !important; }



.header {
  display: flex;
  align-items: center;
  @include padding(4, y);
  @include margin(4, bottom);

  @include media-breakpoint-up(lg) {
    @include margin(8, bottom);
  }

  &__logo-wrapper {
    display: flex;
    align-items: center;
    @include margin(4, right);

    @include media-breakpoint-up(lg) {
      @include margin(5, right);
    }
  }

  &__logo {
    height: 34px;
    margin-top: calc((42px - 34px) / 2);
    margin-bottom: calc((42px - 34px) / 2);
  }
}


.content {
  flex: 1;
}


.footer {
  display: flex;
  justify-content: center;

  margin-top: auto;
  @include padding(4, y);
  @include margin(8, top);

  &__item:not(:first-child)::before {
    content: '•';
    @include padding(3, x);
  }

  @include media-breakpoint-down(sm) {
    @include text-sm;
  }
}
