$audio-indicator-bar-margin: 2;
$audio-indicator-bar-height: 3;


.audio-indicator {
  display: flex;
  flex-direction: column-reverse;
  position: relative;  // Performance optimization: reduce paint surface

  &--bars {
    flex-direction: row;
    @include margin(-$audio-indicator-bar-margin, right);
  }

  &__item {
    @include icon(null);
    width: 100%;
    opacity: 0;

    &--bar {
      height: spacer(3);
      border-radius: $border-radius-sm;
    }
  }

  &__bar-wrapper {
    flex: auto;
    background: theme-color('neutral', 200);
    border-radius: $border-radius-sm;
    overflow: hidden;
    @include margin($audio-indicator-bar-margin, right);
  }
}
