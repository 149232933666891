@use 'sass:map';


.badge {
  @include -badge-base;
  @include -badge-color;
  &.clickable {
    @include -badge-clickable;
    @include -badge-color-clickable;
  }

  @include -badge-rect;
  &--round {
    @include -badge-round;
  }

  @include -badge-size(md);
  @each $size in map.keys($badge-sizes) {
    &--#{$size} {
      @include -badge-size($size);
    }
  }

  @each $theme-color in $theme-color-names {
    &--#{$theme-color} {
      @include -badge-color($theme-color);
      &.clickable {
        @include -badge-color-clickable($theme-color);
      }
    }
  }


  &__icon {
    @include -badge-icon-base;
    @include -badge-icon-pos(left);

    &--right {
      @include -badge-icon-pos(right);
    }
  }
}
