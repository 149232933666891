.range {
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  width: 100%;
  padding: 0;
  height: input-height();

  &:focus {
    outline: none;
  }

  &::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &::-moz-focus-outer {
    border: 0;
  }

  @include slider-thumb {
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-appearance: none;
    width: $range-thumb-size;
    height: $range-thumb-size;
    border-radius: calc($range-thumb-size / 2);
    margin-top: calc(($range-track-height - $range-thumb-size) / 2);
    background: theme-color('neutral', 400);
    @include shadow(2);
  }

  &:not(:disabled, .disabled) {
    @include slider-thumb {
      cursor: pointer;
      background: theme-color('primary', 300);
      border: 0;
      @include clickable-transition;
    }

    &:hover,
    &:focus {
      @include slider-thumb {
        background: theme-color('primary', 400);
      }
    }
  }


  @include slider-track {
    border: 0;
    color: transparent;

    height: $range-track-height;
    border-radius: calc($range-track-height / 2);
    background: theme-color('neutral', 300);
  }
}
