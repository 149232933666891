@import '@fortawesome/fontawesome-pro/scss/brands';
@import '@fortawesome/fontawesome-pro/scss/regular';
@import '@fortawesome/fontawesome-pro/scss/light';
@import '@fortawesome/fontawesome-pro/scss/fontawesome';


@import 'utils/index';

@import 'core/index';
@import 'dashboard/index';
@import 'meeting/index';
@import 'scheduling/index';

// TODO: delete this after scheduling has been ported to Angular. We need this because Jquery UI
// injects datepickers into the root document instead of the shadow root.
@import 'jquery-ui/themes/base/core.css';
@import 'jquery-ui/themes/base/datepicker.css';
@import 'jquery-ui/themes/base/theme.css';
