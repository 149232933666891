@import 'input';

@import 'checkbox';
@import 'radio';
@import 'range';
@import 'select';
@import 'toggle';



.hint {
  @include margin(1, top);
  @include text-muted;
}


.errorlist {
  @include margin(1, top);
  @include margin(0, bottom);
  @include padding(0, left);
  list-style-type: none;
  @include text-theme-color('error', 500);
}


.form-group {
  @include margin($form-group-margin-bottom, bottom);

  display: flex;
  flex-direction: column;

  position: relative;

  &--sm {
    @include margin($form-group-margin-bottom-sm, bottom);
  }
}
