/// Import the part of the framework that doesn't emit styles
@import 'index';


/// Unify defaults across browsers
@import 'reboot';

/// We use a few components from Angular Material. Set their theme.
@import 'material-theming';

/// Utility css classes. Can be used straight inside html. Also overrides the default styling of
/// some HTML tags, like h1, label, pre,...
@import 'styles';


.grecaptcha-badge {
  display: none;
}


// Angular.js injects these rules from its js. Because our css is blocking but our js isn't, we
// include this here as well.
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}


// Make sure the CDK overlays (used for Angular modals) are shown above angular.js modals.
.cdk-overlay-container {
  z-index: 10000 !important;
}
