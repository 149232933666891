.screen-tile {
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: 0 0;
  }


  &__viewport-miniature {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
}
