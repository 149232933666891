@use 'sass:map';

.card-grid {
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-up(sm) {
    display: grid;
    gap: spacer(6);
    grid-template-columns: repeat(auto-fill, minmax(28em, 1fr));

    &--md {
      grid-template-columns: repeat(3, 1fr);
    }

    &--sm {
      grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
    }
  }

  &__card {
    @include card(sm);

    @include margin(0);
    text-decoration: none;

    display: flex;
    flex-direction: column;
    gap: spacer(4);

    &,
    &:hover,
    &:focus,
    &:visited {
      color: inherit;
      text-decoration: none;
    }

    &--clickable {
      @include -card-clickable;
    }

    &--inactive {
      @include bg-theme-color('neutral', 200);
    }
  }
}
