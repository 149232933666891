@mixin border-round($radius: $border-radius, $important: null) {
  border-radius: $radius $important;
}

@mixin border-round-sm {
  @include border-round($border-radius-sm);
}
@mixin border-round-lg {
  @include border-round($border-radius-lg);
}

@mixin border(
  $color: $color-border,
  $width: $border-width,
  $pos: '',
  $style: solid,
  $important: null,
) {
  @if $pos == '' {
    border: $width $style $color $important;

  } @else if $pos == x {
    @include border($color, $width, left, $style, $important);
    @include border($color, $width, right, $style, $important);

  } @else if $pos == y {
    @include border($color, $width, top, $style, $important);
    @include border($color, $width, bottom, $style, $important);

  } @else {
    border-#{$pos}: $width $style $color $important;
  }
}
