@mixin spacing($prop, $size: 1, $pos: '', $important: null) {
  @if $pos == '' {
    #{$prop}: spacer($size) $important;

  } @else if $pos == x {
    @include spacing($prop, $size, left, $important);
    @include spacing($prop, $size, right, $important);

  } @else if $pos == y {
    @include spacing($prop, $size, top, $important);
    @include spacing($prop, $size, bottom, $important);

  } @else {
    #{$prop}-#{$pos}: spacer($size) $important;
  }
}


@mixin margin($size: 1, $pos: '', $important: null) {
  @include spacing(margin, $size, $pos, $important);
}
@mixin padding($size: 1, $pos: '', $important: null) {
  @include spacing(padding, $size, $pos, $important);
}
