@use 'sass:color';
@use 'sass:map';
@use 'sass:list';
@use 'sass:string';
@use '@angular/material' as mat;

@import 'functions';

// These variables should always be kept up to date with the first lines of variables.js.
// This is not very ideal, but it's only a few variables, and there's no standardized solution to
// sharing variables between sass and javascript yet.

$tooltip-spacing: 6px;
$modal-attention-duration: 200ms;
// Changed to 425px on 5/11/2020 because it seems a bug in Google Chrome on Android
// does not use the correct media queries when the device width is exactly 424px (as on an
// Honor 8X phone. Chrome version: 86.0.4240.110
// Changed to 433px on 14/01/2021 because the motorola G9 plus showed in desktop mode
$phone-max-size: 433px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);


// These variables don't need to be synced

// Colors

// The values of the theme colors are set by Django in theme_colors.html
$theme-color-names: (
  'primary',
  'neutral',
  'teal',
  'info',
  'success',
  'warning',
  'error',
  'violet',

  // Special colors

  // Only used in a few places: marketing button, upgrade badge,...
  'marketing',
  // This represents the brand color of the view organization, even on pages that should not be
  // whitelabeled. It is used almost nowhere, but we do need it in a few places (e.g. the contact
  // widget preview).
  'brand'
);
$theme-color-weights: (100, 200, 300, 400, 500);

// The weight from which text on this background should be inverted.
// E.g.: for theme color "warning", text on backgrounds 100-400 should be regular black, while text
// on background 500 should be white.
$theme-color-default-min-invert-weight: 300;
$theme-color-min-invert-weights: (
  'neutral': 500,
  'warning': 500,
);

@each $name in $theme-color-names {
  @if not map.has-key($theme-color-min-invert-weights, $name) {
    $theme-color-min-invert-weights: map.set(
      $theme-color-min-invert-weights,
      $name,
      $theme-color-default-min-invert-weight,
    );
  }
}

$colordef-page: ('neutral', 200);
$colordef-surface: ('neutral', 100);
$colordef-border: ('neutral', 300);
$colordef-text: ('teal', 500);
$colordef-text-muted: ('neutral', 500);
$colordef-text-inverted: ('neutral', 100);
$colordef-text-muted-inverted: ('neutral', 300);
$colordef-a: ('info', 400);
$colordef-a-inverted: ('info', 200);

$color-page: theme-color($colordef-page...);
$color-surface: theme-color($colordef-surface...);
$color-border: theme-color($colordef-border...);
$color-overlay: rgba(50, 50, 50, .8);
$color-text: theme-color($colordef-text...);
$color-text-muted: theme-color($colordef-text-muted...);
$color-text-inverted: theme-color($colordef-text-inverted...);
$color-text-muted-inverted: theme-color($colordef-text-muted-inverted...);
$color-a: theme-color($colordef-a...);
$color-a-inverted: theme-color($colordef-a-inverted...);

$icon-opacity: .84;

$color-tooltip-bg: theme-color('teal', 500, .9);
$color-tooltip-text: theme-color('neutral', 100);



// Border

$border-width: 1px;
$border-width-thick: 3px;


// Shadows

$shadow-color: rgba(42, 59, 77, .24);
// stylelint-disable indentation
$shadows: (
    0: none,
    2: (      0  2px  6px 0 $shadow-color),
   -2: (inset 0  2px  6px 0 $shadow-color),
    4: (      0  6px 10px 0 $shadow-color),
   -4: (inset 0  6px 10px 0 $shadow-color),
   16: (      0 24px 32px 0 $shadow-color),
  -16: (inset 0 24px 32px 0 $shadow-color),
);
// stylelint-enable indentation


// Fonts

$font-family-base: 'Inter', trebuchet ms, verdana, arial, sans-serif;
$font-family-heading: 'Inter', trebuchet ms, verdana, arial, sans-serif;
$font-family-btn: $font-family-base;
$font-family-monospace: monospace;

$font-size-base: 14px;
$font-size-sm: 12px;
$font-size-lg: 16px;

$font-sizes-btn: (
  sm: 14px,
  md: 14px,
  lg: 16px,
  xl: 16px,
);

$line-height-base: 21px;
$line-height-sm: 18px;
$line-height-lg: 24px;
$line-height-btn: 18px;
$line-height-btn-sm: 18px;
$line-height-btn-lg: 24px;

$font-weight-base: 400;
$font-weight-btn: 500;

$h-font-size: (
  page-title: 24px,
  h1: 24px,
  h2: 18px,
  h3: 16px,
  h4: 12px,
  h5: 14px,
);
$h-font-weight: (
  page-title: 700,
  h1: 700,
  h2: 500,
  h3: 500,
  h4: 700,
);
$h-letter-spacing: (
  page-title: 0,
  h1: 0,
  h2: 0,
  h3: 0,
  h4: .6px,
);
$h-text-transform: (
  page-title: none,
  h1: none,
  h2: none,
  h3: none,
  h4: uppercase,
);
$h-line-height: (
  page-title: 30px,
  h1: 30px,
  h2: 24px,
  h3: 21px,
  h4: 18px,
  h5: 18px,
);
$h-margin-top: (
  page-title: 24px,
  h1: 18px,
  h2: 18px,
  h3: 18px,
  h4: 18px,
);
$h-margin-top-first: (
  page-title: 24px,
  h1: 0,
  h2: 0,
  h3: 0,
  h4: 0,
);
$h-margin-bottom: (
  page-title: 24px,
  h1: 10px,
  h2: 10px,
  h3: 10px,
  h4: 10px,
);




// Sizes

$spacer: 3px;
$spacers: (
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
  10, 12, 14, 16, 18, 20,
  24, 28, 32, 36, 40,
  48, 56, 64, 72, 80,
);

$border-radius: 4px;
$border-radius-sm: 3px;
$border-radius-lg: 10px;


// Transitions

$transition-duration-fast: 100ms;
$transition-duration-medium: 200ms;
$transition-duration-slow: 500ms;
$transition-property-clickable: background, color, border-color, box-shadow;




// Grid

$container-paddings: (
  xs: 3,
  sm: 5,
  md: 8,
  lg: 12,
  xl: 16,
);

$modal-margins: (
  xs: (x: 1, y: 2),
  sm: (x: 2, y: 4),
  md: (x: 4, y: 8),
  lg: (x: 6, y: 12),
  xl: (x: 8, y: 16),
);

$grid-gutter: 5;
$grid-num-columns: 12;





// Modal
$modal-backdrop-color: theme-color('teal', 400, .5);
$modal-backdrop-blur: 5px;
$modal-header-height: spacer(20);


// Scrollbar
$scrollbar-width: 10px;
$color-scrollbar-track: #e1e3e4;
$color-scrollbar-thumb: #acadae;
$color-scrollbar-thumb-hover: color.adjust($color-scrollbar-thumb, $lightness: -5%);
$color-scrollbar-thumb-active: color.adjust($color-scrollbar-thumb, $lightness: -8%);


// Material theme
$material-primary-palette: (
  50: theme-color('primary', 100),
  100: theme-color('primary', 100),
  200: theme-color('primary', 200),
  300: theme-color('primary', 200),
  400: theme-color('primary', 300),
  500: theme-color('primary', 300),
  600: theme-color('primary', 400),
  700: theme-color('primary', 400),
  800: theme-color('primary', 500),
  900: theme-color('primary', 500),
  contrast: (
    50: text-on-bg-theme-color('primary', 100),
    100: text-on-bg-theme-color('primary', 100),
    200: text-on-bg-theme-color('primary', 200),
    300: text-on-bg-theme-color('primary', 200),
    400: text-on-bg-theme-color('primary', 300),
    500: text-on-bg-theme-color('primary', 300),
    600: text-on-bg-theme-color('primary', 400),
    700: text-on-bg-theme-color('primary', 400),
    800: text-on-bg-theme-color('primary', 500),
    900: text-on-bg-theme-color('primary', 500),
  ),
);

$material-accent-palette: (
  50: theme-color('warning', 100),
  100: theme-color('warning', 100),
  200: theme-color('warning', 200),
  300: theme-color('warning', 200),
  400: theme-color('warning', 300),
  500: theme-color('warning', 300),
  600: theme-color('warning', 400),
  700: theme-color('warning', 400),
  800: theme-color('warning', 500),
  900: theme-color('warning', 500),
  contrast: (
    50: text-on-bg-theme-color('warning', 100),
    100: text-on-bg-theme-color('warning', 100),
    200: text-on-bg-theme-color('warning', 200),
    300: text-on-bg-theme-color('warning', 200),
    400: text-on-bg-theme-color('warning', 300),
    500: text-on-bg-theme-color('warning', 300),
    600: text-on-bg-theme-color('warning', 400),
    700: text-on-bg-theme-color('warning', 400),
    800: text-on-bg-theme-color('warning', 500),
    900: text-on-bg-theme-color('warning', 500),
  ),
);

$material-warn-palette: (
  50: theme-color('error', 100),
  100: theme-color('error', 100),
  200: theme-color('error', 200),
  300: theme-color('error', 200),
  400: theme-color('error', 300),
  500: theme-color('error', 300),
  600: theme-color('error', 400),
  700: theme-color('error', 400),
  800: theme-color('error', 500),
  900: theme-color('error', 500),
  contrast: (
    50: text-on-bg-theme-color('error', 100),
    100: text-on-bg-theme-color('error', 100),
    200: text-on-bg-theme-color('error', 200),
    300: text-on-bg-theme-color('error', 200),
    400: text-on-bg-theme-color('error', 300),
    500: text-on-bg-theme-color('error', 300),
    600: text-on-bg-theme-color('error', 400),
    700: text-on-bg-theme-color('error', 400),
    800: text-on-bg-theme-color('error', 500),
    900: text-on-bg-theme-color('error', 500),
  ),
);

$material-primary: mat.define-palette($material-primary-palette);
$material-accent: mat.define-palette($material-accent-palette);
$material-warn: mat.define-palette($material-warn-palette);

$material-theme: mat.define-light-theme(
  (
    color: (
      primary: $material-primary,
      accent: $material-accent,
      warn: $material-warn,
    ),
  ),
);
