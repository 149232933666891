@use 'sass:map';


$field-padding-y: 4;
$field-padding-x: 6;


@mixin -field-base {
  @include padding($field-padding-y, y);
  @include padding($field-padding-x, x);
  @include border-round;
  @include border;
  position: relative;
  text-align: left;

  display: flex;
  align-items: center;
}

@mixin -field-clickable {
  @include clickable;
  @include not-disabled {
    &:hover,
    &:focus,
    &:active,
    &.active {
      @include bg-theme-color('neutral', 200);
      border-color: theme-color('neutral', 400);
    }
  }
}

@mixin -field-color($theme-color) {
  @include bg-theme-color($theme-color, 100);
  @include text-theme-color($theme-color, 500);
  border-color: theme-color($theme-color, 200);

  a,
  .a {
    &:not(.btn, .a-plain) {
      @include a-override {
        text-decoration: underline;
        @include text-theme-color($theme-color, 500);
      }
    }
  }
}

@mixin -field-color-clickable($theme-color) {
  @include not-disabled {
    &:hover,
    &:focus,
    &:active,
    &.active {
      @include bg-theme-color($theme-color, 200);
      border-color: theme-color($theme-color, 300);
    }
  }
}


@mixin field($theme-color: null, $clickable: false) {
  @include -field-base;

  @if $clickable {
    @include -field-clickable;
  }

  @if $theme-color {
    @include -field-color($theme-color);
    @if $clickable {
      @include -field-color-clickable($theme-color);
    }
  }
}
