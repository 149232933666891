@use 'sass:color';
@use 'sass:math';


$recording-progress-bar-height: 5px;


.recording {
  &__wrapper {
    @include margin(12, top);
  }

  &__title {
    @include h(h3);
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-border;
  }


  display: flex;
  align-items: center;
  @include padding(2, y);

  &__text {
    min-width: 25em;
    flex: 1 0 auto;
  }

  &__name {
    @include margin(1, bottom);
  }

  &__info {
    @include text-muted;
  }


  &__buttons {
    display: flex;
    > * {
      @include margin(2, left);
    }
  }


  &__track {
    background: $color-scrollbar-track;
    height: $recording-progress-bar-height;
    border-radius: calc($recording-progress-bar-height / 2);
    margin-bottom: math.floor(math.div(-$recording-progress-bar-height, 2));
    overflow: hidden;
  }


  &__bar {
    height: $recording-progress-bar-height;
    background: theme-color('primary', 300);

    &--medium {
      background: theme-color('warning', 300);
    }
    &--high {
      background: theme-color('error', 300);
    }
  }
}



.btn--recording {
  @keyframes recording {
    40% { background: theme-color('error', 300, .25); }
    60% { background: theme-color('error', 300, .25); }
  }
  animation: recording 2s infinite;

  &:not(:disabled, .disabled) {
    @include set-icon-color(theme-color('error', 400, $icon-opacity));
  }
}
