$shared-pointer-size: 20px;
$shared-pointer-notify-size: 12px;

@keyframes notify {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  85% {
    transform: scale(3);
    opacity: 0;
  }
}

@mixin notify-circle {
  position: absolute;
  content: '';
  background: inherit;

  opacity: 0;
  border-radius: 50%;

  transform-origin: 50% 50%;
  animation: notify $shared-pointer-click-duration ease-out;

  top: -$shared-pointer-notify-size;
  left: -$shared-pointer-notify-size;
  width: 2 * $shared-pointer-notify-size;
  height: 2 * $shared-pointer-notify-size;
}


.shared-pointer {
  position: absolute;
  top: 0;
  left: 0;

  pointer-events: none;
  opacity: .8;


  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    @include icon($shared-pointer-size);
  }


  &__name {
    position: absolute;
    white-space: nowrap;
    left: $shared-pointer-size * .75;
    top: $shared-pointer-size * .6;
    font-size: 10px;
  }


  &__notify {
    position: absolute;
    top: 0;
    left: 0;

    &::before,
    &::after {
      @include notify-circle;
    }
    &::after {
      animation-delay: $shared-pointer-click-duration * .15;
    }
  }
}
