@use 'sass:map';


.dashboard {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;

  &__sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: $sidebar-width;
    height: 100%;
    background: theme-color('neutral', 200);
    @include border($pos: right);

    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      transform: translateX(-110%);
      @include shadow(16);
      @include z(dashboard-phone, sidebar);

      &--expanded {
        transform: none;
      }
      &--expanded-add,
      &--expanded-remove {
        transition: transform $transition-duration-medium;
      }
    }

  }

  &__sidebar-chat-icon {
    margin-left: 6px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    color: theme-color('success', 300);
    transform: scale(.5, .45);
    background: theme-color('neutral', 400);

    &--online {
      background: theme-color('success', 300);
    }
    &--away {
      background: theme-color('error', 300);
    }
    &--offline {
      background: theme-color('neutral', 400);
    }
  }

  &__sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: $modal-backdrop-color;
    transition-property: opacity;

    @include media-breakpoint-down(md) {
      @include z(dashboard-phone, sidebar-overlay);

      &.ng-hide {
        opacity: 0;
      }
      &.ng-hide-remove,
      &.ng-hide-add {
        transition-duration: $transition-duration-medium;
      }
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }


  &__header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $phone-header-height;

    @include z(dashboard-phone, header);

    display: none;
    @include media-breakpoint-down(md) {
      display: flex;
    }
  }

  &__header {
    flex: 1;
    background: theme-color('neutral', 200);
    @include border($pos: bottom);
    margin: 0;
  }


  &__main {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    padding-left: $sidebar-width;

    @include media-breakpoint-down(md) {
      @include z(dashboard-phone, main);
      padding-left: 0;
      padding-top: $phone-header-height;
    }
  }

  &__content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    container-type: inline-size;
  }

  &__footer {
    flex: 0 0 auto;
  }

  &__logo {
    // Dirty selector, but dashboard__logo--custom needs to use the html height attribute, so we
    // can't even have something like `height: auto` on it.
    &:not(&--custom, &--icon) {
      height: 34px;
      margin: (42px - 34px) / 2;
    }

    &--icon {
      flex: none;
      width: 42px;
      height: 42px;
      @include margin(2, right);
    }
  }

  &__logo-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    margin-right: auto;
  }
}



.sidebar {
  $padding-left: 3;
  $padding-right: 3;
  $icon-size: icon-size();
  $icon-margin: 8px;

  @include padding(3);

  height: 0;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;

  &__logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    @include margin(3, top);
    @include margin(8, bottom);
    @include padding($padding-left, left);
    @include padding($padding-right, right);

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__menu,
  &__sub-menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__menu {
    flex: 0 1 auto;
    overflow-y: auto;
    @include margin(5, bottom);
  }


  &__menu-item {
    position: relative;
    @include margin(1, bottom);
    @include a-plain;

    @include padding(3, y);
    @include padding($padding-left, left);
    @include padding($padding-right, right);
    font-weight: 500;
    letter-spacing: .2px;

    display: flex;
    user-select: none;
    cursor: pointer;


    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      @include border-round;
    }


    &:hover,
    &:focus,
    &:active,
    &.active {
      @include text-on-bg-theme-color('neutral', 300);
      &::before {
        @include bg-theme-color('neutral', 300);
      }
    }

    &:active,
    &.active {
      &::before {
        box-shadow: $btn-active-shadow;
      }
    }
    &.selected {
      @include text-on-bg-theme-color('primary', 300);
      &::before {
        @include bg-theme-color('primary', 300);
      }
    }


    &--sub {
      $margin-left: $icon-size + $icon-margin;
      padding-left: $margin-left + spacer($padding-left);
      &::before {
        left: $margin-left;
      }
    }
  }

  &__menu-icon {
    width: $icon-size;
    height: $icon-size;
    margin-right: $icon-margin;

    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__menu-icon-notification {
    position: absolute;
    top: 10px;
    left: 13px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #f23934;
  }


  &__menu-label {
    @include margin(1, top);
    margin-right: auto;
    line-height: 18px;
  }


  &__menu-group-toggle {
    margin-left: $icon-margin;
    width: $icon-size;
    height: $icon-size;

    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: transform $transition-duration-fast;
  }


  &__menu-group {
    > .sidebar__sub-menu {
      display: none;
    }

    &.active {
      .sidebar__menu-group-toggle {
        transform: rotate(90deg);
      }
      > .sidebar__sub-menu {
        display: block;
      }
    }
  }


  &__menu-subtitle {
    @include margin(5, top);
    @include margin(1, bottom);
    @include padding($padding-left, left);
    @include padding($padding-right, right);

    display: flex;
    text-transform: uppercase;
    letter-spacing: .04em;
    font-size: $font-size-sm;
    font-weight: bold;
    cursor: default;
  }



  &__signup {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__hr {
    @include margin(2, x);
    @include margin(4, y);
    @include border($pos: bottom);
  }
}
