.table {
  width: 100%;

  &__wrapper {
    @include border-round;
    @include border;
  }


  td {
    @include padding(3);
    vertical-align: top;
    background: theme-color('neutral', 100);
  }
  th {
    @include padding(3);
    @include padding(4, top);
    font-weight: 500;
    font-size: 13px;
    vertical-align: bottom;
  }
  td,
  th {
    @include border($pos: bottom);
    &:first-child {
      @include padding(6, left);
    }
    &:last-child {
      @include padding(6, right);
    }
  }

  &--striped tbody tr:nth-of-type(even) td {
    background: theme-color('neutral', 200);
  }

  // stylelint-disable no-descending-specificity
  > thead > tr > th {
    background: theme-color('neutral', 200);
    &:first-child {
      border-top-left-radius: $border-radius;
    }
    &:last-child {
      border-top-right-radius: $border-radius;
    }
  }
  > tbody > tr:last-child > td {
    border: none;
    &:first-child {
      border-bottom-left-radius: $border-radius;
    }
    &:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
  // stylelint-enable no-descending-specificity


  &__row {
    &--attention {
      @keyframes tr__attention {
        0% { background: transparent; }
        10% { background: theme-color('primary', 100); }
        100% { background: transparent; }
      }

      animation: tr__attention 6s;
    }
  }


  @media (hover) {
    tr:not(:hover) &__show-on-row-hover:not(.has-dropdown) {
      visibility: hidden;
    }

    &--hoverable tbody tr:not(.table__no-hover):hover td {
      background: theme-color('neutral', 200);
    }
  }


  @each $pos, $pos-s in ('': '', x: x, y: y, left: l, right: r, top: t, bottom: b) {
    @for $size from 0 through 5 {
      &--p#{$pos-s}-#{$size} td {
        @include padding($size, $pos);
      }
    }
  }


  // stylelint-disable no-descending-specificity
  &.align-top,
  tr.align-top {
    th,
    td {
      vertical-align: top;
    }
  }
  &.align-middle,
  tr.align-middle {
    th,
    td {
      vertical-align: middle;
    }
  }
  &.align-bottom,
  tr.align-bottom {
    th,
    td {
      vertical-align: bottom;
    }
  }
  // stylelint-enable no-descending-specificity


  &__cell--icon {
    width: btn-size(round);
  }
}
