@use 'sass:map';
@use 'sass:math';


.row {
  display: flex;
  flex-wrap: wrap;
  @include margin(-$grid-gutter, x);
}



%grid-column {
  width: 100%;
  @include padding($grid-gutter, x);
}


@each $breakpoint in map.keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint);

  @for $i from 1 through $grid-num-columns {
    .col#{$infix}-#{$i} {
      @extend %grid-column;
    }
  }
  .col#{$infix},
  .col#{$infix}-auto {
    @extend %grid-column;
  }


  @include media-breakpoint-up($breakpoint) {
    .col#{$infix} {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col#{$infix}-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }

    @for $i from 1 through $grid-num-columns {
      .col#{$infix}-#{$i} {
        flex: 0 0 math.percentage(math.div($i, $grid-num-columns));
        // Add a `max-width` to ensure content within each column does not blow out
        // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
        // do not appear to require this.
        max-width: math.percentage(math.div($i, $grid-num-columns));
      }
    }


    .order#{$infix}-first {
      order: -1;
    }

    .order#{$infix}-last {
      order: $grid-num-columns + 1;
    }

    @for $i from 0 through $grid-num-columns {
      .order#{$infix}-#{$i} {
        order: $i;
      }
    }


    @for $i from 0 through ($grid-num-columns - 1) {
      @if not ($infix == '' and $i == 0) {  // Avoid emitting useless .offset-0
        .offset#{$infix}-#{$i} {
          margin-left: if($i == 0, 0, math.percentage(math.div($i, $grid-num-columns)));
        }
      }
    }
  }
}
