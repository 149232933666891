@use 'sass:map';


@keyframes loading-body {
  0% {
    stroke-dasharray: 64;
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(.415, .180, .745, .715);
  }
  40% {
    stroke-dasharray: 52;
    stroke-dashoffset: -52;
  }
  40.0001% {
    stroke-dasharray: 52;
    stroke-dashoffset: 52;
    animation-timing-function: cubic-bezier(.225, .285, .585, .820);
  }
  80% {
    stroke-dasharray: 64;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 64;
    stroke-dashoffset: 0;
  }
}


.loading {
  width: icon-size();
  height: icon-size();
  stroke: var(--icon-color);

  @each $size in map.keys($icon-sizes) {
    &--#{$size} {
      width: icon-size($size);
      height: icon-size($size);
    }
  }

  &--custom {
    width: 100%;
    height: 100%;
  }

  .loading-body {
    animation: loading-body 2.5s infinite;
  }
}



$loading-modal-icon-size: 40px;

.loading-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  @include margin(8, y);

  &__icon {
    @include icon($loading-modal-icon-size);
  }

  &__text {
    @include text-h(h3);
    @include margin(4, left);
  }
}
