$color-review-bg: #73747e;

.signup {
  &__form {
    // Offset the margin above the first row of input fields.
    @include margin(-5, top);
  }


  &__separated-text {
    display: flex;
    justify-content: center;
    @include margin(-1, bottom);

    > * {
      @include margin(1, bottom);

      &:not(:first-child)::before {
        content: '•';
        @include padding(3, x);
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }


  &__options-separator {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include padding(6);
    text-transform: uppercase;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      height: 1px;
      background: $color-border;
    }

    &::before {
      left: 0;
      right: calc(50% + 1.4em);
    }
    &::after {
      left: calc(50% + 1.4em);
      right: 0;
    }
  }
}



.label--signup {
  position: relative;
  top: 35px;
  left: spacer(2);
  @include padding(1, x);

  display: inline-flex;
  align-self: flex-start;
  @include border-round;
  @include text-muted;
  opacity: 1;

  transform-origin: 0 0;
  z-index: 1;
  transition-property: transform, background;
  transition-duration: $transition-duration-fast;
  pointer-events: none;

  &.label--required::after {
    display: none;
  }
}


.input--signup {
  height: 42px;
  @include padding(3, x);

  &:focus,
  &:not(:placeholder-shown) {
    + .label--signup {
      transform: scale(.9) translateY(-27px);
      background: white;
    }
  }
}

.label--signup-phone {
  @extend .label--signup;
  transform: scale(.9) translateY(-27px);
  background: white;
}
