@use 'sass:map';


.tag {
  @include -tag-base;

  @include -tag-size(md);
  @each $size in map.keys($tag-sizes) {
    &--#{$size} {
      @include -tag-size($size);
    }
  }


  &__text {
    @include -tag-text-base;
  }


  &__close {
    @include -tag-close-base;
  }
}
