.dropdown {
  @include dropdown;

  &__list-category {
    @include dropdown-list-category;
  }

  &__list-item {
    @include dropdown-list-item;
  }


  &__list-icon {
    @include dropdown-list-icon;

    &--sm {
      @include dropdown-list-icon(sm);
    }
  }


  &__list-divider {
    @include dropdown-list-divider;
  }
}
