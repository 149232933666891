.profile-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  object-fit: cover;

  &--initials {
    @include bg-theme-color('primary', 300);

    font-family: $font-family-base;
    font-weight: 600;
    user-select: none;
  }
}
