.position-static { position: static !important; }
.position-relative { position: relative !important; }
.position-absolute { position: absolute !important; }
.position-fixed { position: fixed !important; }
.position-sticky { position: sticky !important; }


@each $position in (top, left, right, bottom) {
  .#{$position}-0 { #{$position}: 0 !important; }
  .#{$position}-50 { #{$position}: 50% !important; }
  .#{$position}-100 { #{$position}: 100% !important; }
}

.sticky {
  position: fixed;
  top: 0;
}
