@use 'sass:map';


$special-sizes: (
  auto: (w: auto, h: auto),
  full: (w: 100%, h: 100%),
  screen: (w: 100vw, h: 100vh),
);
$dimensions: (
  w: width,
  h: height,
);
$variants: ('', 'min-', 'max-');


@mixin -sizing($infix) {
  @each $dimension-name, $dimension in $dimensions {
    @each $variant in $variants {
      @each $spacer in $spacers {
        $size: spacer($spacer);
        .#{$variant}#{$dimension-name}#{$infix}-#{$spacer} {
          #{$variant}#{$dimension}: $size !important;
        }
      }

      @each $size-name, $sizes in $special-sizes {
        $size: map.get($sizes, $dimension-name);
        .#{$variant}#{$dimension-name}#{$infix}-#{$size-name} {
          #{$variant}#{$dimension}: $size !important;
        }
      }
    }
  }
}


@each $breakpoint in map.keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @include -sizing($infix);
  }
}

@include media-phone {
  @include -sizing(-phone);
}
@include media-desktop {
  @include -sizing(-desktop);
}
