.onboarding {
  &__organization-properties {
    display: grid;
    grid-template-columns: auto auto;
    gap: spacer(4);

    @include media-breakpoint-up(sm) {
      grid-template-columns: auto auto auto auto;
    }
  }

  &__organization-property {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include card($size: sm, $clickable: true);
    @include padding(5, x);
    @include padding(2, y);
    @include margin(0, bottom);
    @include border-round;

    box-shadow: inset 0 0 0 $border-width transparent;


    &--selected {
      background: theme-color('primary', 100);
      box-shadow: inset 0 0 0 $border-width theme-color('primary', 400);
    }
  }
}
