.video-viewport {
  &__controls-container {
    display: flex;
    flex-direction: column;
    width: auto;

    background: theme-color('teal', 500, .18);
    @include text-inverted;
    @include border-round-lg;
  }

  &__controls-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    margin-right: calc(-1 * var(--js-user-tile-footer-item-margin));
    margin-bottom: calc(-1 * var(--js-user-tile-footer-item-margin));
  }


  &__miniature-container {
    position: relative;
    border: 1px solid rgba(255, 255, 255, .8);
    background: rgba(0, 0, 0, .8);

    cursor: default;
  }


  &__miniature-tile {
    position: absolute;

    background: rgba(255, 255, 255, .8);

    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
}
