$breakpoint-phone: sm;

.compact-settings {

  position: relative;

  @include padding(0);

  outline: 1px solid $color-border;
  @include border-round;
  overflow: hidden;

  &__header {
    @include bg-theme-color('neutral', 200);

    @include padding(4, x);
    @include padding(4, y);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__section {
    @include padding(6);
    @include border($pos: top);
  }

  &__section-title {
    @include text-h(h4);
    @include text-muted;
    @include margin(2, bottom);

    display: flex;
    align-items: center;
    width: max-content;
    max-width: 100%;
  }

  &__section-form {
    display: flex;
    @include media-breakpoint-down($breakpoint-phone) {
      flex-direction: column;
    }
  }
}
