$panel-width: 300px;
$min-panel-height: 300px;
$icon-margin: 3;

.settings {
  display: flex;
  flex-direction: column;
  align-items: stretch;


  &--dual {
    @include media-desktop {
      flex-direction: row;

      > .settings__panel {
        width: $panel-width;
      }
    }
  }


  &__panel {
    flex: auto;

    display: flex;
    flex-direction: column;
  }


  &__separator {
    background: $color-border;

    @include margin(8, y);
    flex: 0 0 1px;

    @include media-desktop {
      @include margin(0, y);
      @include margin(12, x);
    }
  }


  &__row {
    display: flex;
    @include margin(5, bottom);

    &:last-child {
      @include margin(0, bottom);
    }
  }


  &__icon {
    @include icon;
    flex: none;
    @include margin($icon-margin, right);
    margin-top: -1px;  // Align with the label, which is only 21px high.
  }


  &__row-content {
    flex: auto;
  }


  &__select {
    width: 0;
    flex: 1;
  }



  &__test-field {
    position: relative;
    z-index: 0;
    background: theme-color('primary', 100);
    height: btn-size(height);
    border-radius: $border-radius;
    overflow: hidden;

    display: flex;
    align-items: center;
    padding-left: btn-size(padding-x);
  }


  &__test-video-container {
    display: flex;
    position: relative;

    border-radius: $border-radius;
    overflow: hidden;
    background: theme-color('teal', 400);
  }

  &__loading-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 10%;

    background: rgba(black, .25);
    @include set-icon-color(white);
  }

  &__test-video {
    max-width: 200px;
    max-height: 200px;
    transform: scaleX(-1);
    object-fit: cover;
  }


  &__test-audio-progress {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    height: 100%;
    background: theme-color('primary', 300);
  }
}
