@use 'sass:map';


$card-sizes: (
  lg: (
    padding-x: spacer(24),
    padding-y: spacer(16),
    margin-bottom: spacer(16),
  ),
  md: (
    padding-x: spacer(12),
    padding-y: spacer(8),
    margin-bottom: spacer(12),
  ),
  sm: (
    padding-x: spacer(6),
    padding-y: spacer(4),
    margin-bottom: spacer(4),
  )
);

@function card-size($property, $size: md) {
  @return map.get(map.get($card-sizes, $size), $property);
}


@mixin -card-base {
  background: $color-surface;
  border-radius: $border-radius;
  box-shadow: inset 0 0 0 1px $color-border;
}

@mixin -card-clickable {
  @include clickable('background, box-shadow');

  &:not(:disabled, .disabled) {
    &:hover,
    &:focus {
      box-shadow: inset 0 0 0 2px theme-color('primary', 400);
    }

    &:active,
    &.active {
      background: theme-color('primary', 100);
    }
  }
}

@mixin -card-no-padding {
  padding: 1px;
}

@mixin -card-size($size: md) {
  @include padding(card-size(padding-x, $size), x);
  @include padding(card-size(padding-y, $size), y);
  @include margin(card-size(margin-bottom, $size), bottom);
}


@mixin card($size: md, $clickable: false, $no-padding: false) {
  @include -card-base;
  @include -card-size($size);
  @if $clickable {
    @include -card-clickable;
  }
  @if $no-padding {
    @include -card-no-padding;
  }
}
