$notifications-position-top: 50px;
$notification-margin-x: 10px;

.notification {
  &__wrapper {
    position: fixed;
    z-index: 10003;
    top: $notifications-position-top;
    left: $notification-margin-x;
    right: $notification-margin-x;
    pointer-events: none;
  }

  display: flex;
  flex-direction: column;
  min-width: 150px;
  max-width: 600px;
  border-radius: $border-radius;
  @include margin(4, bottom);
  @include shadow(4);
  background: $color-surface;
  pointer-events: initial;

  &.ng-enter,
  &.ng-leave {
    transition: transform $transition-duration-medium;
  }
  &.ng-enter:not(.ng-enter-active),
  &.ng-leave.ng-leave-active {
    transform: translateX(calc(100% + #{$notification-margin-x}));
  }


  @keyframes notification-attention {
    0% { transform: translateX(0); }
    50% { transform: translateX(-15px); }
    100% { transform: translateX(0); }
  }
  &--attention {
    animation: notification-attention 150ms linear 3;
  }


  &__inner {
    display: flex;
    border-radius: $border-radius;
    @include padding(2);

    &--success {
      border-left: 3px solid theme-color('success', 300);
      background: theme-color('success', 100);
    }
    &--warning {
      border-left: 3px solid theme-color('warning', 300);
      background: theme-color('warning', 100);
    }
    &--error {
      border-left: 3px solid theme-color('error', 300);
      background: theme-color('error', 100);
    }
  }


  &__message {
    flex: auto;
    align-self: center;
    overflow-wrap: break-word;
  }


  &__progress-container {
    @include margin(2, x);
    @include margin(2, bottom);
    height: 10px;

    border-radius: 5px;
    background: theme-color('primary', 100);
    overflow: hidden;
  }
  &__progress {
    height: 100%;
    background: theme-color('primary', 300);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;

      $progress-color1: transparent;
      $progress-color2: rgba(255, 255, 255, .2);
      background-image: linear-gradient(
        45deg,
        $progress-color1 25%,
        $progress-color2 25%,
        $progress-color2 50%,
        $progress-color1 50%,
        $progress-color1 75%,
        $progress-color2 75%,
        $progress-color2,
      );
      background-size: 16px 16px;

      // Based on bootstrap progress bars
      @keyframes progress-bar-animation {
        from { background-position: 16px 0; }
        to { background-position: 0 0; }
      }
      animation: progress-bar-animation .8s linear infinite;
      transition: width $transition-duration-fast ease-out;
    }
  }
}
