$chat-width: 310px;
$chat-height: 450px;
$chat-unread-badge-size: 1.5em;

.main-chat {
  position: relative;
  display: flex;


  &__unread-badge {
    position: absolute;
    top: 0;
    left: icon-size() - 3px;
    margin-left: 0 !important;

    @include text-sm;
    background: theme-color('primary', 300);
    @include text-inverted;

    height: $chat-unread-badge-size;
    min-width: $chat-unread-badge-size;
    border-radius: calc($chat-unread-badge-size / 2);

    display: flex;
    align-items: center;
    justify-content: center;

    &--phone {
      left: auto;
      right: 8px;
      top: 8px;
    }
  }


  &__dropdown {
    display: flex;
    flex-direction: column;
    width: $chat-width;
    height: $chat-height;
    max-height: calc(100vh - #{$footer-height + 2 * $dropdown-margin});
  }

  &__dropdown-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__modal {
    flex: auto;
    display: flex;
    flex-direction: column;
  }


  &__bubbles {
    position: absolute;
    bottom: calc(100% + #{spacer(3)});
    display: flex;
    width: $chat-width;
  }
}


%chat__bubble {
  z-index: z(app, chat-bubbles);
  cursor: pointer;
  @include shadow(2);
}

.chat {
  &__sender--bubble {
    @extend %chat__bubble;
  }

  &__bubble {
    @extend %chat__bubble;
    @include margin(2, left);
    border-radius: $border-radius;
    background: $color-surface;
    overflow: hidden;
  }

  &__bubble-content {
    @include padding(2);

    &:hover,
    &:focus,
    &:active,
    &.active {
      background: $btn-hover-color;
    }

    &:active,
    &.active {
      box-shadow: $btn-active-shadow;
    }
  }
}
