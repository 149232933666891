$tooltip-max-width: 400px;
$tooltip-arrow-size: 4px;


@mixin tooltip {
  position: absolute;

  top: 0;
  left: 0;
  width: $tooltip-max-width;
  max-width: 96%;

  &.ng-hide-remove {
    transition: opacity $transition-duration-fast;
  }
  &.ng-hide {
    opacity: 0;
  }
}


@mixin -tooltip-arrow-base {
  position: absolute;
  left: -$tooltip-arrow-size;
  top: -$tooltip-arrow-size;
  border-color: transparent;
  border-width: $tooltip-arrow-size;
  border-style: solid;
}

@mixin -tooltip-arrow-pos($pos) {
  @if $pos == bottom {
    top: -2 * $tooltip-arrow-size;
    border-bottom-color: $color-tooltip-bg;
  } @else if $pos == top {
    top: 0;
    border-top-color: $color-tooltip-bg;
  } @else if $pos == right {
    left: -2 * $tooltip-arrow-size;
    border-right-color: $color-tooltip-bg;
  } @else if $pos == left {
    left: 0;
    border-left-color: $color-tooltip-bg;
  } @else {
    @error 'Invalid tooltip position: ' + $pos;
  }
}

@mixin tooltip-arrow($pos) {
  @include -tooltip-arrow-base;
  @include -tooltip-arrow-pos($pos);
}


@mixin tooltip-content {
  position: absolute;
  background: $color-tooltip-bg;
  color: $color-tooltip-text;
  border-radius: $border-radius;

  max-width: 100%;
  overflow-wrap: break-word;

  @include padding(1, y);
  @include padding(2, x);
}



tooltips {
  position: fixed;
  z-index: 10004;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}


.tooltip {
  @include tooltip;

  &__arrow {
    @include -tooltip-arrow-base;
    @each $pos in bottom top right left {
      &--#{$pos} {
        @include -tooltip-arrow-pos($pos);
      }
    }
  }

  &__content {
    @include tooltip-content;
  }
}
