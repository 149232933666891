@mixin dropdown {
  pointer-events: all;
  background: $color-surface;
  border-radius: $border-radius;
  overflow: hidden;
  @include shadow(4);
  max-width: calc(100vw - #{spacer(6)});
  width: 100%;

  &--scrollable {
    max-height: 98vh;
    overflow: auto;
  }
}



@mixin dropdown-list-item {
  @include a-plain;

  display: flex;
  align-items: center;

  padding: calc((btn-size(height) - $line-height-base) / 2) btn-size(padding-x);
  cursor: default;

  &.disabled,
  &:disabled {
    @include text-muted;
  }

  &:not(.disabled, :disabled) {
    @include text-default;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background: theme-color('neutral', 200);
    }

    &:active,
    &.active {
      background: theme-color('info', 100);
    }
  }

  @at-root a#{&} {
    text-decoration: none;
    color: inherit;
  }

  &--selected {
    background: theme-color('neutral', 200);
  }
}


@mixin dropdown-list-category {
  @include text-h(h4);

  display: flex;
  align-items: center;

  padding: calc((btn-size(height) - $line-height-base) / 2) btn-size(padding-x);
}


@mixin dropdown-list-icon($size: md) {
  @include icon($size);
  @include margin(3, right);
}


@mixin dropdown-list-divider {
  @include margin(2, y);
  height: 1px;
  background: $color-border;
}
