$hint-arrow-width: 60px;
$hint-distance: 4px;

.tutorial {
  @media (max-height: 660px), (max-width: 660px) {
    display: none;
  }

  &__item {
    position: absolute;

    &--main {
      position: absolute;
      top: 60%;
      left: 0;
      width: 100%;

      display: flex;
      justify-content: center;
    }

    &--content {
      z-index: z(app, tutorial-arrow);
    }
  }


  &__main-box {
    @include padding(5);
    border-radius: $border-radius-lg;
    font-size: 120%;
    text-align: center;

    &--translucent {
      background: rgba(white, .6);
    }
  }


  &__arrow {
    position: absolute;
    width: $hint-arrow-width;

    &--cam-mic {
      transform: rotate(-28deg);
      right: 0;
      bottom: $hint-distance + 6px;
    }

    &--mic {
      transform: scaleX(-1) rotate(-28deg);
      left: 0;
      bottom: $hint-distance + 6px;
    }

    &--settings {
      transform: scaleX(-1) rotate(-28deg);
      bottom: $hint-distance + 6px;
      left: $hint-arrow-width * -.4;
    }

    &--invite {
      transform: scaleX(-1) rotate(-28deg);
      bottom: $hint-distance + 6px;
      left: $hint-arrow-width * -.4;
    }

    &--content {
      transform: rotate(262deg);
      bottom: -14px;
      right: $hint-distance;
    }

    &--participants {
      transform: rotate(-65deg);
      bottom: 0;
      right: $hint-distance - 10px;
    }
  }

  &__field {
    position: absolute;

    &--cam-mic {
      text-align: center;
      bottom: 86px;
      width: 200px;
      transform: translateX(-50%);
    }

    &--settings {
      text-align: center;
      bottom: 86px;
      left: -9px;
      width: 200px;
      transform: translateX(-50%);
    }

    &--invite {
      white-space: nowrap;
      bottom: 86px;
      left: -9px;
      transform: translateX(-50%);
    }

    &--content {
      white-space: nowrap;
      right: 32px;
      bottom: 46px;
    }

    &--participants {
      bottom: 73px;
      right: $hint-distance + 4px;
    }
  }
}
