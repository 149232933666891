.checkbox {
  opacity: 0;
  position: absolute;

  &__wrapper {
    min-height: $radio-size;
    @include margin(2, bottom);
  }

  &__label {
    position: relative;
    margin-bottom: 0;
    line-height: $checkbox-size;
    padding-left: $checkbox-size + spacer($checkbox-margin);
    @include margin(2, bottom);

    display: flex;
    align-items: center;

    &::before {
      // Weird bug in Safari: when using `content: ''`, the checkboxes disappear on
      // /integrations/teamleader, but not on /docs/styleguide. ¯\_(ツ)_/¯
      content: ' ';  // stylelint-disable-line no-irregular-whitespace
      position: absolute;
      top: calc(($line-height-base - $checkbox-size) / 2);
      left: 0;
      width: $checkbox-size;
      height: $checkbox-size;
      border-radius: $border-radius;
      transition-property: background, border-color;
      transition-duration: $transition-duration-fast;

      background: theme-color('neutral', 300);
      @include border(transparent);
    }

    &::after {
      @extend .far;
      content: '\f00c';
      position: absolute;
      left: 5px;
      top: 3px;

      transition-property: opacity, color;
      transition-duration: $transition-duration-fast;

      opacity: 0;
      color: theme-color('neutral', 400);
    }

    &--input {
      height: input-height();
      margin-bottom: 0;

      &::before {
        top: calc((input-height() - $checkbox-size) / 2);
      }

      &::after {
        // magic number derived from checkbox heighth
        top: 4px + calc((input-height() - $checkbox-size) / 2);
      }
    }
  }


  &:checked {
    ~ .checkbox__label::after {
      opacity: 1;
    }
  }


  &:not(:disabled, .disabled) {
    ~ .checkbox__label::before {
      background: theme-color('neutral', 100);
      border-color: theme-color('neutral', 400);
    }
    ~ .checkbox__label::after {
      color: theme-color('neutral', 100);
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      ~ .checkbox__label::before {
        background: theme-color('neutral', 300);
        border-color: theme-color('neutral', 500);
      }
    }

    &:checked {
      ~ .checkbox__label::before {
        background: theme-color('primary', 300);
        border-color: theme-color('primary', 400);
      }

      &:hover,
      &:focus,
      &:active,
      &.active {
        ~ .checkbox__label::before {
          background: theme-color('primary', 400);
        }
      }
    }
  }
}
