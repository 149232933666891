@use 'sass:map';


.btn {
  @include -btn-base;


  @include -btn-style-default;
  &--secondary {
    @include -btn-style-secondary;
  }
  &--link {
    @include -btn-style-link;
  }
  &--opaque {
    @include -btn-style-opaque;
  }
  &--translucent {
    @include -btn-style-translucent;
  }
  &--inverted {
    @include -btn-style-inverted;
  }
  &--translucent-inverted {
    @include -btn-style-translucent-inverted;
  }
  @each $theme-color in $theme-color-names {
    &--#{$theme-color} {
      @include -btn-style-background($theme-color);
    }
    &--text-#{$theme-color} {
      @include -btn-style-text($theme-color);
    }
    &--outline-#{$theme-color} {
      @include -btn-style-outline($theme-color);
    }
  }


  @include -btn-size;
  @each $size in map.keys($btn-sizes) {
    &--#{$size} {
      @include -btn-size($size);
    }
  }


  @include -btn-shape;
  &--square {
    @include -btn-shape(square);
  }
  &--round {
    @include -btn-shape(round);
  }


  &--progress {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      height: 3px;
      left: 0;
      width: 0;
      animation: btn--progress calc(var(--duration) * 1ms) both linear;

      border-radius: 1.5px;
      background: theme-color('primary', 300);
    }

    @keyframes btn--progress {
      from { width: 0%; }
      to { width: 100%; }
    }
  }



  &__icon {
    @include btn-icon;

    &--right {
      @include btn-icon(right);
    }
  }
}


.btn-height {
  height: btn-size(height);
}
