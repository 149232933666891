@use 'sass:map';

.modal-deprecated {
  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;

    @each $breakpoint, $margins in $modal-margins {
      @include media-breakpoint-up($breakpoint) {
        @include padding(map.get($margins, x), x);
        @include padding(map.get($margins, y), y);
      }
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    background: $modal-backdrop-color;

    &.ng-hide {
      opacity: 0;
    }
    &.ng-hide-remove {
      display: flex !important;
      transition-property: opacity;
      transition-duration: $transition-duration-medium;
    }


    &--init {
      &,
      > .modal {
        display: none;
      }
    }

    &--blur {
      backdrop-filter: blur($modal-backdrop-blur);
    }
  }


  display: flex;

  background: $color-surface;
  border-radius: $border-radius;
  overflow: hidden;
  @include shadow(16);


  width: 100%;
  max-width: map.get($grid-breakpoints, md);
  max-height: 100%;

  @each $breakpoint, $width in $grid-breakpoints {
    &--#{$breakpoint} {
      max-width: $width;
    }
  }


  &.ng-hide {
    opacity: 0;
    transform: translateY(-1.5rem);
  }
  &.ng-hide-remove {
    display: flex !important;
    transition-property: opacity, transform;
    transition-delay: calc($transition-duration-medium / 2);
    transition-duration: $transition-duration-medium;
  }


  &--attention {
    @keyframes modal-attention {
      0% { transform: scale(1); }
      50% { transform: scale(1.08); }
      100% { transform: scale(1); }
    }

    animation: modal-attention $modal-attention-duration;
  }


  &__body {
    display: flex;
    flex-direction: column;
    flex: auto;
    max-width: 100%;
  }


  &__header {
    flex: none;
    display: flex;
    align-items: center;

    height: $modal-header-height;
    @include padding(2, y);
    @include padding(6, x);

    @include text-h(h3);
    background: theme-color('neutral', 200);
    @include border;
  }

  &__icon {
    @include icon;
    @include margin(3, right);
    flex-shrink: 0;
  }

  &__title {
    flex-grow: 1;
    @include text-truncate;
  }

  &__close {
    @include margin(1, left);
    @include margin(-2, right);
  }


  &__overflow-wrapper {
    flex: auto;
    overflow: auto;
    display: flex;
    align-items: flex-start;
  }


  &__content {
    flex: auto;
    display: flex;
    flex-direction: column;
    min-height: 0;

    @include padding(6);

    &--no-padding {
      padding: 0;
    }
  }
}
