.image-input {
  &__preview-wrapper {
    align-self: flex-start;
    display: flex;
    align-items: center;
    position: relative;
  }

  &__btns-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 100%;

    display: flex;
    flex-direction: column;
  }

  &__btns-vert-wrapper {
    margin-top: auto;
    @include margin(2, bottom);
    min-height: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btns {
    $color-background: theme-color('neutral', 500, .18);
    display: flex;
    border-radius: 50%;
    background: $color-background;
    box-shadow: 0 0 15px 10px $color-background;

  }
}
