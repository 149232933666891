@use 'sass:color';


$star-size: icon-size(lg);
$star-padding: 6px;

$star-color: theme-color('warning', 300);
$star-color-disabled: theme-color('neutral', 300);
$star-color-hover: theme-color('warning', 400);

.feedback {
  &__stars {
    display: flex;
    justify-content: center;

    @include p;
  }

  &__star {
    @include icon($star-size + $star-padding * 2);
    padding: $star-padding;
    cursor: pointer;

    @include set-icon-color($star-color-disabled);

    &.hover {
      @include set-icon-color($star-color-hover);
    }
    &.selected:not(.hover) {
      @include set-icon-color($star-color);
    }
  }


  &__textarea {
    width: 100%;
    height: 100%;
  }
}
