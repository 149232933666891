.input {
  @include input;

  &--sm {
    @include -input-size(sm);
  }

  &--error {
    @include -input-error;
  }
  &--with-icon {
    @include -input-with-icon(left);
  }
  &--with-icon-right {
    @include -input-with-icon(right);
  }

  &--number {
    max-width: 100px;
  }

  &__icon {
    @include -input-icon-base;
    @include -input-icon-size(md);
    @include -input-icon-pos(left);

    &--sm {
      @include -input-icon-size(sm);
    }

    &--right {
      left: auto;
      @include input-icon(right);
    }
  }

  &__prefix,
  &__postfix {
    @include -input-base;
    @include -input-size;
    @include bg-theme-color('neutral', 200);
    width: auto;

    &--sm {
      @include -input-size(sm);
    }
  }
}



label,
.label {
  @include label;

  &--h3 {
    @include h(h3);
  }

  &--required {
    @include required;
  }
}


.required {
  @include required;
}


.textarea {
  @include input;
  height: auto;
  $padding-y: calc((var(--input-height) - $line-height-base) / 2);
  padding-top: $padding-y;
  padding-bottom: $padding-y;
}



.input-auto-size {
  &__wrapper {
    position: relative;
    display: inline-block;
    max-width: 100%;
  }

  &__sizer {
    @include input;
    visibility: hidden;
    white-space: pre;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}


.input-group {
  display: flex;
  position: relative;

  > * {
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
