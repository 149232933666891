@import '~mathquill/build/mathquill.css';

@import './variables';
@import './mixins';

@import './ui';
@import './components';
@import './main';



// Mobile: allow collapsing navigation.
@include media-phone {
  .treadmill {
    position: absolute;
    visibility: hidden;
    width: 1px;
    height: 99999999999999999999px;
  }
}


.meeting-container {
  position: fixed;
  top: 0;
  bottom: 0;  // Don't use "height: 100%": SEOquake extension breaks this
  left: 0;
  right: 0;
  overflow: hidden;
  background: $color-page;
  touch-action: none;

  @include media-desktop {
    min-width: 700px;
  }

  display: flex;
  flex-direction: column;
}



.no-support {
  &__copy-btn {
    position: relative;
  }

  &__copy-tooltip {
    display: none;
    position: absolute;
    top: 110%;
    left: 50%;
  }
  &__copy-tooltip-content {
    transform: translate(-50%);
  }
}
