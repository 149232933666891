@use 'sass:map';


$badge-sizes: (
  sm: (
    badge: 18px,
    icon: 14px,
    text: 12px,
  ),
  md: (
    badge: 24px,
    icon: 14px,
    text: 14px,
  ),
  lg: (
    badge: 30px,
    icon: 24px,
    text: 16px,
  ),
);

@function badge-size($property, $size: md) {
  @return map.get(map.get($badge-sizes, $size), $property);
}


@mixin -badge-base {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-size: var(--badge-font-size);
  font-weight: 500;

  height: var(--badge-size);
}

@mixin -badge-clickable {
  @include clickable-transition;
  @include not-disabled {
    cursor: pointer;

    &:active,
    &.active {
      box-shadow: $btn-active-shadow;
    }
  }
}

@mixin -badge-size($size: md) {
  --badge-size: #{badge-size(badge, $size)};
  --badge-icon-size: #{badge-size(icon, $size)};
  --badge-font-size: #{badge-size(text, $size)};
}

@mixin -badge-color($theme-color: null) {
  @if not $theme-color {
    background: theme-color('neutral', 500, .12);
    border: 1px solid theme-color('neutral', 500, .12);
    @include text-default;
  } @else {
    background: theme-color($theme-color, 100);
    border-color: theme-color($theme-color, 200);
    @include text-theme-color($theme-color, 500);
  }
}

@mixin -badge-color-clickable($theme-color: null) {
  &:not(:disabled, .disabled) {
    @if not $theme-color {
      &:hover,
      &:focus {
        background: theme-color('neutral', 500, .18);
      }
    } @else {
      &:hover,
      &:focus {
        background: theme-color($theme-color, 200);
      }
    }
  }
}

@mixin -badge-rect {
  min-width: var(--badge-size);
  @include padding(2, x);
  @include border-round;
}
@mixin -badge-round {
  width: var(--badge-size);
  border-radius: 50%;
  @include padding(0, x);
}


@mixin -badge-icon-base {
  @include icon(var(--badge-icon-size));
}
@mixin -badge-icon-pos($pos: left) {
  @if $pos == left {
    @include margin(2, right);
  } @else if $pos == right {
    @include margin(2, left);
    order: 1;
  } @else {
    @error 'Unknown $pos: "' + $pos _ '"';
  }
}



@mixin badge($theme-color: null, $size: md, $round: false, $clickable: false) {
  @include -badge-base;
  @include -badge-size($size);
  @include -badge-color($theme-color);
  @if $clickable {
    @include -badge-clickable;
    @include -badge-color-clickable($theme-color);
  }
  @if $round {
    @include -badge-round;
  } @else {
    @include -badge-rect;
  }
}


@mixin badge-icon($pos: left) {
  @include -badge-icon-base;
  @include -badge-icon-pos($pos);
}
