@mixin clickable-image-wrapper(
  $selector: '.clickable-image',
  $color-hover: $color-page,
  $opacity-hover: .85,
  $color-active: $color-page,
  $opacity-active: .75
) {
  cursor: pointer;

  &:hover {
    background: $color-hover;
    #{$selector} {
      opacity: $opacity-hover;
    }
  }

  &:active {
    background: $color-active;
    #{$selector} {
      opacity: $opacity-active;
    }
  }
}
