@mixin promo-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include border-round;
  background: theme-color('primary', 100);

  @include margin(3, y);
  @include padding(8, y);
  @include padding(3, x);

  @include media-breakpoint-up(sm) {
    @include margin(10, y);
    @include padding(16, y);
    @include padding(8, x);
  }
}
