@use 'sass:map';


@function container-width($breakpoint) {
  $padding: spacer(map.get($container-paddings, $breakpoint));
  @return map.get($grid-breakpoints, $breakpoint) - 2 * $padding;
}

@mixin container($breakpoint, $query-type: 'media') {
  max-width: map.get($grid-breakpoints, $breakpoint);

  // Don't let the padding increase any further, otherwise the container will get narrower as the
  // screen gets wider.
  @if $query-type == 'media' {
    @include media-breakpoint-up($breakpoint) {
      @include padding(map.get($container-paddings, $breakpoint), x);
    }
  } @else if $query-type == 'container' {
    @include container-breakpoint-up($breakpoint) {
      @include padding(map.get($container-paddings, $breakpoint), x);
    }
  }
}
