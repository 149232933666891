@each $position-name, $position in ('': '', x: x, y: y, l: left, r: right, t: top, b: bottom) {
  @each $spacer in $spacers {
    .p#{$position-name}-#{$spacer} {
      @include padding($spacer, $position, !important);
    }
    .m#{$position-name}-#{$spacer} {
      @include margin($spacer, $position, !important);
    }
    .m#{$position-name}--#{$spacer} {
      @include margin(-$spacer, $position, !important);
    }
  }

  .m#{$position-name}-auto {
    @include margin(auto, $position, !important);
  }
}
