@use 'sass:color';
@use 'index' as fw;


html {
  height: 100%;
  font-size: fw.$font-size-base;
  font-family: fw.$font-family-base;
  // Mobile Safari: prevent font scaling in landscape
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-text-size-adjust: 100%;

  line-height: fw.$line-height-base;

  color: fw.$color-text;
  @include fw.set-icon-color(fw.theme-color(fw.$colordef-text..., $opacity: fw.$icon-opacity));
}

html,
body {
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;  // Necessary for the tooltips
}


:root {
  touch-action: pan-x pan-y;

  --safe-area-inset-top: 0;
  --safe-area-inset-left: 0;
  --safe-area-inset-right: 0;
  --safe-area-inset-bottom: 0;

  @supports (margin: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top, 0);
    --safe-area-inset-left: env(safe-area-inset-left, 0);
    --safe-area-inset-right: env(safe-area-inset-right, 0);
    --safe-area-inset-bottom: env(safe-area-inset-bottom, 0);
  }
}


*,
*::before,
*::after {
  box-sizing: border-box;
}


.fa,
.fas,
.far,
.fal,
.fab {
  color: fw.theme-color(fw.$colordef-text..., $opacity: fw.$icon-opacity);
}


hr {
  border: 0;
  border-bottom: 1px solid fw.$color-border;
  margin: 0;
  @include fw.margin(4, bottom);
}


table {
  border-collapse: collapse;
}
td,
th {
  text-align: left;
}


button,
[type='button'],
[type='reset'],
[type='submit'] {
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-appearance: button;
}


input,
button,
select,
optgroup,
textarea {
  margin: 0;  // Remove the margin in Firefox and Safari
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  // Disable annoying ligatures like --> becoming →
  font-variant-ligatures: none;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}


textarea {
  resize: none;
}


ul,
ol {
  line-height: inherit;
  margin-top: 0;
  @include fw.p;
}


iframe {
  border: 0;
}



*[svg] {
  display: flex;
}

svg {
  width: auto;
  height: 100%;
}



// Scrollbars
::-webkit-scrollbar {
  width: fw.$scrollbar-width;
  height: fw.$scrollbar-width;
}
::-webkit-scrollbar-track {
  background: fw.$color-scrollbar-track;
  border-radius: calc(fw.$scrollbar-width / 2);
}
::-webkit-scrollbar-thumb {
  background: fw.$color-scrollbar-thumb;
  border-radius: calc(fw.$scrollbar-width / 2);

  &:hover {
    background: fw.$color-scrollbar-thumb-hover;
  }
  &:active {
    background: fw.$color-scrollbar-thumb-active;
  }
}
