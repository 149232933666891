$footer-margin-y: 2;
$footer-item-margin: 2;
$footer-btn-size-phone: 58px;
$footer-audio-indicator-width: 8px;
$footer-height: 2 * spacer($footer-margin-y) + btn-size(height);


@mixin media-vertical-footer {
  @media (orientation: landscape) and (max-height: 500px) {
    @content;
  }
}


.meeting-footer {
  display: flex;
  align-items: center;
  // Don't increase the footer height when some buttons are collapsed to round buttons (which are
  // a bit higher).
  height: btn-size(height);

  @include margin(-$footer-item-margin, right);


  &--phone {
    height: $footer-btn-size-phone;
    justify-content: center;
    margin-bottom: var(--safe-area-inset-bottom);

    @include media-vertical-footer {
      flex-direction: column;
      height: 100%;
      width: $footer-btn-size-phone;

      @include margin(0);
      @include margin(-$footer-item-margin, bottom);
      margin-left: var(--safe-area-inset-left);
    }
  }


  &__separator {
    flex: 0 0 1px;
    align-self: stretch;
    background: $color-border;
    @include margin(2, y);
    @include margin($footer-item-margin, right);
  }


  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    @include margin($footer-item-margin, right);

    &--phone {
      flex: 0 1 $footer-btn-size-phone;
      pointer-events: initial;

      // Shrink on narrow screens
      width: 0;
      @include media-vertical-footer {
        width: auto;
        height: 0;
        @include margin(0, right);
        @include margin($footer-item-margin, bottom);
      }
    }
  }

  &__logo {
    display: flex;
    .meeting-footer__logo-wrapper--collapsed & {
      display: none;
    }
  }
  &__icon {
    display: none;
    .meeting-footer__logo-wrapper--collapsed & {
      display: flex;
    }
  }


  &__btn {
    flex: none;
    position: relative;

    &.btn--round .meeting-footer__collapse {
      display: none;
    }

    &--phone {
      width: $footer-btn-size-phone;
      height: $footer-btn-size-phone;
      border-radius: calc($footer-btn-size-phone / 2);
    }
  }


  &__audio-indicator {
    position: absolute;
    left: 2px;
    width: $footer-audio-indicator-width;
    top: 50%;
    transform: translateY(-50%);
    @include margin(0, left);

    &--phone {
      left: 10px;
    }
  }
}
