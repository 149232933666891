.search-user {
  &__query {
    padding-right: spacer(2) + icon-size();
  }

  &__loading {
    position: absolute;
    right: spacer(1);
    top: 50%;
    transform: translateY(-50%);
  }


  &__name {
    display: flex;
    flex: 1;
    width: 0;
    align-items: center;
    height: btn-size(height);
    @include padding(2, x);
    @include border-round;
    border: 1px solid $color-border;
  }

  &__name-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
