@use 'sass:map';


.text-base { @include text-base; }
.text-sm { @include text-sm; }
.text-lg { @include text-lg; }


@each $h in map.keys($h-font-size) {
  .text-#{$h} {
    @include text-h($h);
  }

  #{$h},
  .#{$h} {
    @include h($h);
  }
}

h1 + h2,
h1 + h3,
h1 + h4,
h2 + h3,
h2 + h4,
h3 + h4 {
  margin-top: 0;
}


p,
.p {
  @include p;
}
.pp {
  @include pp;
}
a,
.a {
  @include a;
}
.a-plain {
  @include a-plain;
}

.ul-plain {
  padding-left: 0;
  list-style-type: none;
}



// Color

.text-default { @include text-default(!important); }
.text-muted { @include text-muted(!important); }
.text-inverted { @include text-inverted(!important); }
.text-muted-inverted { @include text-muted-inverted(!important); }


// Font

.text-monospace { font-family: $font-family-monospace !important; }
.text-heading { font-family: $font-family-heading !important; }
.line-height-0 { line-height: 0 !important; }



// Alignment

.text-justify { text-align: justify !important; }
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }

// Word wrapping
.text-truncate { @include text-truncate; }
.text-break {
  overflow-wrap: break-word;
}

@for $lines from 1 through 10 {
  .line-clamp-#{$lines} {
    // stylelint-disable-next-line value-no-vendor-prefix
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    overflow: hidden;
  }
}

// White space
.text-wrap { white-space: normal !important; }
.text-nowrap { white-space: nowrap !important; }
.text-pre { white-space: pre !important; }
.text-pre-line { white-space: pre-line !important; }
.text-pre-wrap { white-space: pre-wrap !important; }


// Transformation

.text-lowercase { text-transform: lowercase; }
.text-uppercase { text-transform: uppercase; }
.text-capitalize { text-transform: capitalize; }



// Weight and italics

.text-light { font-weight: 200; }
.text-normal { font-weight: 400; }
.text-medium { font-weight: 500; }
.text-bold { font-weight: 700; }
.text-italic { font-style: italic; }



.list {
  &--no-style {
    list-style: none;
  }
  &--no-padding {
    padding-left: 0;
  }
}



// Pre and code

code {
  @include padding(2, x);
  @include padding(1, y);
  @include border-round-sm;
  background: theme-color('neutral', 200);
  @include text-theme-color('primary', 500);
  font-size: 90%;
}


pre {
  font-size: 90%;
  overflow: auto;

  @include field;
  background: theme-color('neutral', 200);
  border: 0;

  @include p;

  code {
    background: none;
    font-size: inherit;

    &,
    &:hover,
    &:active {
      color: inherit;
    }
  }
}



.loading-dots::after {
  content: '...';
  animation: loading-dots 2s steps(4, jump-none) infinite;
}

@keyframes loading-dots {
  from { clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%); }
  to { clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); }
}
