$header-item-margin: 2;


.meeting-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include margin(-$header-item-margin, right);


  &--phone {
    margin-top: var(--safe-area-inset-top);

    @include media-vertical-footer {
      flex-direction: column-reverse;
      @include margin(0);
      @include margin(-$header-item-margin, bottom);
      margin-right: var(--safe-area-inset-right);
    }
  }

  &__item {
    display: flex;
    @include margin($header-item-margin, right);

    &--phone {
      pointer-events: initial;
      @include media-vertical-footer {
        @include margin(0, right);
        @include margin($header-item-margin, bottom);
      }
    }
  }

  &__btn {
    @extend .meeting-footer__btn;

    &--phone {
      @extend .meeting-footer__btn--phone;
    }
  }

  &__avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;

    &--clickable {
      @include clickable-image-wrapper('.meeting-header__avatar-image');
    }
  }

  &__avatar-image {
    width: 100%;
  }


  &__release-notes-notification {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: theme-color('error', 300);
    }

    &--phone::after {
      width: 12px;
      height: 12px;
    }
  }


  &__keyboard-key {
    @include border-round;
    @include border;
    border-bottom-width: 2px;

    @include padding(3, x);
    @include padding(1, y);
    font-weight: bold;

    min-width: 40px;
    text-align: center;
  }
}
