@use 'sass:map';

$btn-hover-color: theme-color('neutral', 300);
$btn-active-shadow: inset 0 2px 3px theme-color('neutral', 500, .12);

$btn-sizes: (
  sm: (
    height: 30px,
    square: 30px,
    round: 36px,
    icon: 14px,
    font: 14px,
    padding-x: spacer(3),
    icon-margin-left: spacer(-1),
    icon-margin-right: spacer(2),
  ),
  md: (
    height: 36px,
    square: 36px,
    round: 44px,
    icon: 24px,
    font: 14px,
    padding-x: spacer(4),
    icon-margin-left: spacer(-1),
    icon-margin-right: spacer(3),
  ),
  lg: (
    height: 44px,
    square: 44px,
    round: 54px,
    icon: 24px,
    font: 16px,
    padding-x: spacer(8),
    icon-margin-left: spacer(-2),
    icon-margin-right: spacer(4),
  ),
);

@function btn-size($property, $size: md) {
  @return map.get(map.get($btn-sizes, $size), $property);
}

@mixin -btn-size($size: md) {
  --btn-size-height: #{btn-size(height, $size)};
  --btn-size-round: #{btn-size(round, $size)};
  --btn-size-icon: #{btn-size(icon, $size)};
  --btn-size-padding-x: #{btn-size(padding-x, $size)};
  --btn-size-font: #{btn-size(font, $size)};
  --btn-size-icon-margin-left: #{btn-size(icon-margin-left, $size)};
  --btn-size-icon-margin-right: #{btn-size(icon-margin-right, $size)};
}

@mixin -btn-base {
  @include input-group-child;

  padding-left: var(--btn-size-padding-x);
  padding-right: var(--btn-size-padding-x);

  font-family: $font-family-btn;
  font-weight: $font-weight-btn;
  font-size: var(--btn-size-font);

  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;

  border: 1px solid transparent;
  background: transparent;

  &,
  &:hover,
  &:focus,
  &:visited {
    cursor: default;
    text-decoration: none;
    outline: none;
  }

  @include clickable;

  @include not-disabled {
    &:active,
    &.active {
      box-shadow: $btn-active-shadow;
    }
  }
}

@mixin -btn-rect {
  height: var(--btn-size-height);
  @include border-round;
}

@mixin -btn-square {
  flex-grow: 0;
  height: var(--btn-size-height);
  width: var(--btn-size-height);
  @include border-round;

  --btn-size-padding-x: 0;
  --btn-size-icon-margin-left: 0;
  --btn-size-icon-margin-right: 0;
}

@mixin -btn-round {
  flex-grow: 0;
  height: var(--btn-size-round);
  width: var(--btn-size-round);
  border-radius: 50%;

  --btn-size-padding-x: 0;
  --btn-size-icon-margin-left: 0;
  --btn-size-icon-margin-right: 0;
}

@mixin -btn-shape($shape: rect) {
  @if $shape == rect {
    @include -btn-rect;
  } @else if $shape == square {
    @include -btn-square;
  } @else if $shape == round {
    @include -btn-round;
  } @else {
    @error 'Unknown button shape: ' + $shape;
  }
}

@mixin btn-disabled {
  @include a-override {
    @content;
  }
}

@mixin btn-not-disabled {
  @include not-disabled {
    @include a-override {
      @content;
    }
  }
}

@mixin btn-hover {
  @include not-disabled {
    &:hover,
    &:focus,
    &:active,
    &.active {
      @content;
    }
  }
}

@mixin -btn-style-default {
  @include btn-disabled {
    @include text-theme-color('neutral', 400);
  }
  @include btn-not-disabled {
    @include text-theme-color('teal', 500);
  }
  @include btn-hover {
    background: $btn-hover-color;
  }
}

@mixin -btn-style-link {
  @include -btn-style-default;
  @include btn-not-disabled {
    @include text-theme-color($colordef-a...);
  }
}

@mixin -btn-style-secondary {
  @include btn-disabled {
    background: theme-color('neutral', 300);
    border-color: theme-color('neutral', 400);
    @include text-theme-color('neutral', 400);
  }
  @include btn-not-disabled {
    background: theme-color('neutral', 200);
    border-color: theme-color('neutral', 400);
    @include text-theme-color('teal', 500);
  }
  @include btn-hover {
    background: theme-color('neutral', 300);
    border-color: theme-color('neutral', 500);
  }
}

@mixin -btn-style-opaque {
  @include -btn-style-default;
  @include btn-disabled {
    background: $color-surface;
  }
}

@mixin -btn-style-translucent {
  @include -btn-style-default;
  @include btn-disabled {
    background: theme-color('neutral', 100, .60);
  }
  @include btn-hover {
    background: theme-color('neutral', 300, .60);
  }
}

@mixin -btn-style-inverted {
  @include btn-disabled {
    @include text-theme-color('neutral', 300);
  }
  @include btn-not-disabled {
    @include text-theme-color('neutral', 100);
  }
  @include btn-hover {
    background: theme-color('teal', 500, .12);
  }
}

@mixin -btn-style-translucent-inverted {
  @include -btn-style-inverted;
  @include btn-disabled {
    background: theme-color('teal', 500, .18);
  }
  @include btn-hover {
    background: theme-color('teal', 500, .30);
  }
}

@mixin -btn-style-background($theme-color) {
  @include btn-disabled {
    background: theme-color('neutral', 300);
    @include text-theme-color('neutral', 400);
    border-color: theme-color('neutral', 400);
  }

  $color-weight: 300;
  @if $theme-color == 'marketing' {
    $color-weight: 400;
  }

  @include btn-not-disabled {
    @include bg-theme-color($theme-color, $color-weight);
    border-color: theme-color($theme-color, 400);
  }
  @include btn-hover {
    // Avoid changing the text color: don't use the `bg-theme-color` mixin, but instead the
    // `theme-color` function.
    background: theme-color($theme-color, $color-weight + 100);
    border-color: theme-color($theme-color, 500);
  }
}

@mixin -btn-style-text($theme-color) {
  @include btn-disabled {
    @include text-theme-color('neutral', 400);
  }
  @include btn-not-disabled {
    @include text-theme-color($theme-color, 400);
  }
  @include btn-hover {
    background: $btn-hover-color;
    @if $theme-color == 'marketing' {
      background: theme-color('marketing', 100);
    }
  }
}

@mixin -btn-style-outline($theme-color) {
  @include -btn-style-text($theme-color);
  @include btn-disabled {
    border-color: theme-color('neutral', 400);
  }
  @include btn-not-disabled {
    border-color: theme-color($theme-color, 400);
  }
}

@mixin btn($style: default, $theme-color: null, $shape: rect, $size: md) {
  @include -btn-base;
  @include -btn-size($size);
  @include -btn-shape($shape);

  @if $style == default {
    @include -btn-style-default;
  } @else if $style == link {
    @include -btn-style-link;
  } @else if $style == secondary {
    @include -btn-style-secondary;
  } @else if $style == opaque {
    @include -btn-style-opaque;
  } @else if $style == translucent {
    @include -btn-style-translucent;
  } @else if $style == inverted {
    @include -btn-style-inverted;
  } @else if $style == translucent-inverted {
    @include -btn-style-translucent-inverted;
  } @else if $style == background {
    @include -btn-style-background($theme-color);
  } @else if $style == text {
    @include -btn-style-text($theme-color);
  } @else if $style == outline {
    @include -btn-style-outline($theme-color);
  } @else {
    @error 'Unknown button style: ' + $style;
  }
}

@mixin btn-icon($pos: left) {
  @include icon($size: var(--btn-size-icon));
  @if $pos == left {
    margin-left: var(--btn-size-icon-margin-left);
    margin-right: var(--btn-size-icon-margin-right);
  } @else {
    order: 1;
    margin-right: var(--btn-size-icon-margin-left);
    margin-left: var(--btn-size-icon-margin-right);
  }
}
