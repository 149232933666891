@mixin not-disabled {
  &:not(:disabled, .disabled) {
    @content;
  }
}


@mixin clickable-transition($transition-property: $transition-property-clickable) {
  transition-property: $transition-property-clickable;
  transition-duration: $transition-duration-fast;
}

@mixin clickable($transition-property: $transition-property-clickable) {
  @include clickable-transition($transition-property);
  @include not-disabled {
    cursor: pointer;
  }
}
