.meeting-sidebar {
  height: 100%;
  min-width: calc(180px + (100vh - 600px) * .12);
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include padding(2, y);

  > * {
    @include padding(2, x);
  }

  &__top {
    display: flex;
    flex-direction: column;
    z-index: z(app, content-buttons);
    @include padding(2, bottom);
  }

  &__top-inner {
    display: flex;
    flex-direction: column;
    @include margin(-2, bottom);

    > * {
      @include margin(2, bottom);
    }
  }

  &__main {
    flex: 1 0 0;

    display: flex;
    flex-direction: column;
  }

  &__tiles {
    flex: 1 0 0;
  }


  &__separator {
    height: 1px;
    background: $color-border;
  }
}
