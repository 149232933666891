.content-buttons {
  display: flex;
  flex-direction: column;
  @include margin(-1, bottom);

  > * {
    display: flex;
    flex-direction: column;
    @include margin(1, bottom);
  }

  &__btn {
    justify-content: flex-start;
  }
}
