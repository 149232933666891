.style {
  &__text-btn {
    font-family: $font-family-btn;
    font-weight: $font-weight-btn;
    line-height: $line-height-btn;
    font-size: btn-size(font);
  }


  &__color-fields {
    table-layout: fixed;
  }

  &__color-field {
    @include border-round;
    height: spacer(28);
    @include padding(1);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }


  &__shadow-field {
    @include padding(4, y);
    @include padding(6, x);
    @include border-round;
    @include margin(12, bottom);
  }


  &__link {
    @include btn($shape: square);
    @include margin(2, left);
    visibility: hidden;
  }


  &__anchor {
    align-self: flex-start;
  }


  &__header-with-link {
    display: flex;
    align-items: center;

    &:hover > .style__link {
      visibility: visible;
    }
  }
}
