@use 'sass:map';


.border-round {
  @include border-round($border-radius, !important);
}
.border-round-sm {
  @include border-round($border-radius-sm, !important);
}
.border-round-lg {
  @include border-round($border-radius-lg, !important);
}

@mixin -border($name-infix, $color, $width: $border-width) {
  @each $pos in (left, right, top, bottom, x, y, '') {
    $pos-infix: if($pos == '', '', '-' + $pos);
    .border#{$pos-infix}#{$name-infix} {
      @include border($color, $width, $pos, solid, !important);
    }
  }
}

@include -border('', $color-border);
@each $name in $theme-color-names {
  @include -border('-' + $name, theme-color($name, 400));
}
@include -border('-none', transparent, 0);
