@use 'utils/cssFramework' as fw;

.promo-field {
  @include promo-field;


  &__title {
    @include text-h(h1);
  }

  &__steps {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    @include fw.margin(8, y);

    counter-reset: promo-field__steps;

    @include fw.media-breakpoint-up(sm) {
      flex-direction: row;
      @include fw.margin(12, y);
      @include fw.margin(-3, x);

      > * {
        flex: 1;
        min-width: 0;
        @include fw.margin(3, x);
      }
    }
  }


  &__step {
    display: flex;
    align-items: center;
    text-align: start;

    counter-increment: promo-field__steps;

    @include fw.media-breakpoint-up(sm) {
      @include fw.card;
      @include fw.margin(0, bottom);

      flex-direction: column;
      text-align: center;

      @include fw.padding(12, y);
      @include fw.padding(8, x);
    }
  }


  &__step-image {
    width: 120px;

    @include fw.media-breakpoint-up(sm) {
      width: 100%;
    }
  }


  &__step-title {
    display: flex;
    @include fw.margin(6, left);

    &::before {
      content: counter(promo-field__steps) '.';
      margin-right: .5em;
    }

    @include fw.media-breakpoint-up(sm) {
      @include fw.text-h(h2);
      &::before {
        display: none;
      }
    }
  }
}
