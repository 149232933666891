@import './audioIndicator/index';


@mixin audio-indicator-btn {
  background: theme-color('teal', 500, .18);

  &:not(:disabled, .disabled) {
    &:hover,
    &:focus,
    &:active,
    &.active {
      background: theme-color('teal', 500, .30);
    }
    &:active,
    &.active {
      box-shadow: $btn-active-shadow;
    }
  }
}


.user-tile {
  font-size: var(--js-user-tile-font-size);

  &__body {
    &:not(&--collapsed) {
      background: $color-user-tile-background;
      @include text-inverted;
    }
  }


  &__avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }


  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: 0 0;

    // Bugfix for user-tile footer fade-overflow: without this the fade part flashes when
    // enabling video
    background: $color-user-tile-background !important;

    &--mirror {
      transform: translate(100%) scaleX(-1);
    }
  }


  &__raised-hand {
    width: var(--js-user-tile-raised-hand-overlay-size);
    height: var(--js-user-tile-raised-hand-overlay-size);

    transition: transform .7s;
    .tile__overlay.ng-hide.ng-hide-remove > & {
      transform: translateY(200%);
    }

    &--animated {
      @keyframes raise-hand {
        0% { transform: rotate(0); }
        1% { transform: rotate(45deg); }
        3% { transform: rotate(-45deg); }
        5% { transform: rotate(45deg); }
        7% { transform: rotate(-45deg); }
        8% { transform: rotate(0); }
        100% { transform: rotate(0); }
      }

      transform-origin: 50% 70%;
      animation: raise-hand 10s ease 10s infinite;
    }
  }


  &__chrome {
    position: absolute;
    top: var(--js-user-tile-footer-item-margin);
    left: var(--js-user-tile-footer-item-margin);
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    @include z(tile, chrome);

    &--collapsed {
      flex-direction: row-reverse;
    }
    &:not(.user-tile__chrome--collapsed) {
      @include text-inverted;
    }

    pointer-events: none;
  }


  &__header {
    display: flex;
    justify-content: flex-end;
  }


  &__viewport-controls {
    display: flex;
    justify-content: flex-end;
  }



  &__viewport-miniature {
    display: flex;
    justify-content: flex-end;
  }


  &__footer {
    position: relative;
    height: var(--js-user-tile-footer-height);
    min-width: 0;

    display: flex;
    align-items: center;

    &:not(.user-tile__footer--no-scrim) {
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 140%;
        left: calc(-1 * var(--js-user-tile-footer-item-margin));
        right: 0;
        background: linear-gradient(to top, scrim-gradient(rgba(black, .7)));
        pointer-events: none;
      }
    }

    // Show footer items in front of scrim
    > * {
      position: relative;
    }
  }


  &__item {
    pointer-events: all;
    margin-right: var(--js-user-tile-footer-item-margin);
    margin-bottom: var(--js-user-tile-footer-item-margin);

    &--show-on-hover {
      .app--phone:not(.app--phone-with-interface) & {
        display: none;
      }
      @media (hover: hover) {
        .user-tile:not(:hover) & {
          display: none;
        }
      }
    }
  }

  &__icon {
    @extend .user-tile__item;
    &--show-on-hover {
      @extend .user-tile__item--show-on-hover;
    }

    flex: 0 0 auto;
    @include icon(null);
    width: var(--js-user-tile-icon-size);
    height: var(--js-user-tile-icon-size);
    padding: var(--js-user-tile-icon-padding);
    // Override 'btn' class that some icons have
    border: 0;

    box-sizing: content-box;
    border-radius: 50%;

    &--mic-disabled {
      background: theme-color('error', 300);
      @include text-inverted;
    }

    &--mic {
      position: relative;
      background: theme-color('success', 300);
      @include text-inverted;
    }

    &--raised-hand {
      background: theme-color('primary', 300);
      @include text-inverted;
      overflow: hidden;

      > svg {
        @keyframes raised-hand {
          0% { transform: translateY(150%); }
          20% { transform: translateY(150%); }
          100% { transform: translateY(0); }
        }
        animation: .6s raised-hand cubic-bezier(.5, 1.45, .55, 1);
      }
    }
  }


  &__audio-indicator {
    position: absolute;
    width: calc(var(--js-user-tile-icon-size) / 3);
    top: var(--js-user-tile-icon-padding);
    left: calc(var(--js-user-tile-icon-size) / 10);
    @include text-inverted;
  }
}



.floating-tile {
  @include shadow(4);
  border-width: 0;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: z(tile, overlay);

    display: flex;
    justify-content: center;
    align-items: center;

    background: $color-overlay;
    @include text-inverted;
    cursor: pointer;

    &.ng-hide {
      opacity: 0;
    }
    &.ng-hide-add,
    &.ng-hide-remove {
      transition: opacity $transition-duration-medium;
    }
  }
}
