@use 'sass:list';
@use 'sass:map';


@function z($context-id, $id) {
  $context: map.get($z-contexts, $context-id);
  @if not $context {
    @error 'Context #{$context-id} not found';
  }
  $index: list.index($context, $id);
  @if not $index {
    @error 'ID #{$id} not found in context #{$context-id}';
  }
  @return $index;
}


@mixin z($context-id, $id) {
  z-index: z($context-id, $id);
}
