@each $overflow in (visible, hidden, scroll, auto, initial, inherit) {
  .overflow-#{$overflow} {
    overflow: $overflow !important;
  }
  .overflow-x-#{$overflow} {
    overflow-x: $overflow !important;
  }
  .overflow--y#{$overflow} {
    overflow-y: $overflow !important;
  }
}
