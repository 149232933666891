$chat-avatar-size: 2em;
$chat-input-height: 3em;
$emoji-dropdown-columns: 7;

chat {
  flex: auto;
  display: flex;
  height: 0;
}


.chat {
  flex: auto;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;


  &__message-list {
    flex: 0 1 auto;
    overflow: auto;

    display: flex;
    flex-direction: column;

    @include padding(2, x);
    @include padding(2, top);
  }


  &__message {
    display: flex;
    align-items: flex-start;
    @include margin(1, y);

    &--system {
      @include text-muted;
      align-items: flex-end;
      justify-content: space-between;
    }

    &--own {
      flex-direction: row-reverse;
    }
  }


  &__sender {
    position: relative;
    width: $chat-avatar-size;
    height: $chat-avatar-size;
    border-radius: calc($chat-avatar-size / 2);
    overflow: hidden;

    flex: 0 0 auto;
    cursor: default;
  }


  &__initials {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    @include text-inverted;
    font-weight: bold;
  }


  &__avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  &__message-content {
    min-width: 0;

    @include margin(2, x);
    @include padding(2);
    border-radius: $border-radius;
    background: theme-color('neutral', 200);

    overflow-wrap: break-word;

    &--system {
      padding: 0;
      background: none;
    }
  }


  &__new-messages {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;

    @include padding(4, y);
    text-align: center;

    cursor: pointer;
    background: theme-color('primary', 300);
    @include text-inverted;
    &:hover,
    &:focus {
      background: theme-color-hover(primary);
    }
    &:active,
    &.active {
      background: theme-color-active(primary);
    }
  }


  &__only-person {
    flex: none;
    display: flex;
    background: theme-color('warning', 100);
    @include padding(1);
  }


  &__typing {
    @include padding(1, x);
    @include text-muted;

    &:empty::before {
      content: '\a0';
    }
  }

  &__input-wrapper {
    position: relative;
    flex: 0 0 auto;
    display: flex;

    border-top: 1px solid $color-border;
  }

  &__input {
    width: 100%;
    height: $chat-input-height;
    overflow-x: hidden;  // Firefox: don't reserve vertical space for horizontal scrollbar

    &,
    &:not(:disabled, .disabled):hover,
    &:not(:disabled, .disabled):focus {
      box-shadow: none;
    }
  }


  &__btn-wrapper {
    display: flex;
    align-self: flex-end;
  }
}



.emoji-dropdown {
  &__container {
    width: $emoji-dropdown-columns * btn-size(square);
  }
}
