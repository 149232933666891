@import 'sharedPointers/index';

$visibility-placeholder-bg-size: 96px;
$visibility-placeholder-bg-color: theme-color('primary', 500, .2);


.content-tile {
  &__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $content-header-height;
    @include padding(1, left);
    border-bottom: 1px solid $color-border;

    // We only need this so that the whiteboard sidebar doesn't fall over the top bar
    background: $color-surface;

    display: flex;
    align-items: center;

    > * {
      @include margin(1, right);
      &:not(.tile__name) {
        flex: none;
      }
    }

    .tile--inactive-selected & {
      font-weight: bold;
      @include text-theme-color('primary', 500);
    }

    &--inactive {
      height: $content-header-height-inactive;
      background: none;
      user-select: none;
    }
  }


  &__header-divider {
    width: 1px;
    align-self: stretch;
    @include margin(2, y);
    background: $color-border;
  }


  &__input-without-border {
    box-shadow: none;
    &:hover:not(:focus) {
      background: $btn-hover-color;
    }
  }


  &__inactive-wrapper {
    position: absolute;
    height: 100%;
    cursor: pointer;
  }


  &__inactive-body-wrapper {
    position: absolute;
    top: $content-header-height-inactive;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    overflow: hidden;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    &--collapsed {
      @include tooltip-content;
      overflow: visible;
      z-index: z(tiles, tooltip);

      max-width: none;
      top: calc(50%);
      right: 100%;
      bottom: auto;
      left: auto;
      transform: translate(-#{$tooltip-spacing}, -50%);
      @include padding(1);

      &::before {
        @include tooltip-arrow(left);
        content: '';
        top: calc(50% - #{$tooltip-arrow-size});
        left: 100%;
      }

      opacity: 0;
      .content-tile__inactive-wrapper:hover & {
        transition: opacity $transition-duration-fast;
        opacity: 1;
        filter: none;
      }
    }
  }


  &__body--inactive {
    position: relative;
  }
  &__body--collapsed {
    background: white;
  }


  &:not(.tile--inactive) {
    .tile__body {
      z-index: -2;
    }

    &:not(.tile--spectator) {
      .tile__body,
      .tile__catch-events-overlay {
        top: $content-header-height;
      }
    }
  }


  &__visibility-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include icon($visibility-placeholder-bg-size, $visibility-placeholder-bg-color);
  }
}
