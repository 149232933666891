@use '@angular/material' as mat;


@include mat.core;
@include mat.core-theme($material-theme);
@include mat.button-theme($material-theme);


mat-tooltip-component .mdc-tooltip__surface {
  font-size: $font-size-base;
  background: $color-tooltip-bg;
  color: $color-tooltip-text;
  white-space: pre-line;
  padding: spacer(2) spacer(4);
}
