@use 'sass:map';


.icon {
  @include -icon-base;
  @include -icon-color;

  @include -icon-size;
  @each $size in map.keys($icon-sizes) {
    &--#{$size} {
      @include -icon-size($size);
    }
  }

  @for $degrees from 0 to 8 {
    &--rotate-#{$degrees * 45} {
      transform: rotate(#{$degrees * 45}deg);
    }
  }

  &--inline {
    display: inline-flex;
    vertical-align: middle;
  }
}


.use-icon-color {
  @include use-icon-color;
}

.icon-height {
  height: icon-size();
}



.fa,
.fas,
.far,
.fal,
.fab {
  font-size: 16px;

  &.btn__icon {
    font-size: 18px;
  }
}

.fa-lg {
  font-size: 1.3333em !important;
}
.fa-xs {
  font-size: .75em !important;
}
.fa-sm {
  font-size: .875em !important;
}


h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4,
.page-title {
  .fa,
  .fas,
  .far,
  .fal,
  .fab {
    font-size: inherit;
    line-height: 1;
  }
}


// Largely copied from https://developers.google.com/fonts/docs/material_icons
.material-icons {
  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  font-family: 'Material Icons Outlined';
  /* stylelint-enable font-family-no-missing-generic-family-keyword */
  font-weight: normal;
  font-style: normal;
  font-size: icon-size();
  width: icon-size();
  height: icon-size();
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizelegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
