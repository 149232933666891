$knocker-chat-width: 260px;
$knocker-chat-height: 340px;


.knocker-chat {
  display: flex;
  flex-direction: column;
  width: $knocker-chat-width;
  height: $knocker-chat-height;
}
