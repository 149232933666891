.permission-arrow {
  position: fixed;
  z-index: z(meeting, permission-arrow);

  &--x-left {
    left: 100px;
  }
  &--x-center {
    left: 50%;
    transform: translateX(-50%);
  }
  &--y-top {
    top: 150px;
  }
  &--y-center {
    top: 60%;
  }

  display: flex;
  height: 200px;
  pointer-events: none;

  &__animated {
    @keyframes permission-arrow {
      from {
        transform: scale(1);
        opacity: 1;
      }
      to {
        transform: translateY(-75px) scale(1.3);
        opacity: 0;
      }
    }
    animation: permission-arrow 2s ease-out forwards;
  }
}
