.field {
  @include -field-base;
  &.clickable {
    @include -field-clickable;
  }

  @each $theme-color in $theme-color-names {
    &--#{$theme-color} {
      @include -field-color($theme-color);
      &.clickable {
        @include -field-color-clickable($theme-color);
      }
    }
  }
}
