.help {
  &__grid {
    display: grid;
    grid-template-columns: #{'repeat(auto-fill, minmax(min(30em, 100%), 1fr))'};
    grid-gap: 30px;
  }


  &__item {
    @include card($clickable: true);
    @include a-plain;
    @include margin(0);

    display: flex;
  }

  &__icon {
    @include icon(48px);
    @include margin(5, right);
  }
}
