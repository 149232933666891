.radio {
  opacity: 0;
  position: absolute;

  &__wrapper {
    min-height: $radio-size;
    @include margin(2, bottom);
  }

  &__label {
    position: relative;
    height: $radio-size;
    padding-left: $radio-size + spacer($radio-margin);
    display: flex;
    align-items: center;

    min-height: 1em !important;

    &::before {
      // Weird bug in Safari: when using `content: ''`, the checkboxes disappear on
      // /integrations/teamleader, but not on /docs/styleguide. ¯\_(ツ)_/¯
      content: ' ';  // stylelint-disable-line no-irregular-whitespace
      position: absolute;
      top: 0;
      left: 0;
      width: $radio-size;
      height: $radio-size;
      border-radius: 50%;

      transition-property: background, border-color;
      transition-duration: $transition-duration-fast;

      background: theme-color('neutral', 300);
      @include border(transparent);
    }

    &::after {
      content: ' ';  // stylelint-disable-line no-irregular-whitespace
      position: absolute;
      left: calc(($radio-size - $radio-ball-size) / 2);
      top: calc(($radio-size - $radio-ball-size) / 2);
      width: $radio-ball-size;
      height: $radio-ball-size;
      border-radius: 50%;

      transition-property: opacity, background, border-color;
      transition-duration: $transition-duration-fast;

      opacity: 0;
      background: theme-color('neutral', 200);
      @include border(transparent);
    }

    &--input {
      height: input-height();
      margin-bottom: 0;

      &::before {
        top: calc((input-height() - $radio-size) / 2);
      }

      &::after {
        top: calc(($radio-size - $radio-ball-size) / 2) + calc((input-height() - $radio-size) / 2);
      }
    }
  }


  &:checked {
    ~ .radio__label::after {
      opacity: 1;
    }
  }


  &:not(:disabled, .disabled) {
    ~ .radio__label::before {
      background: theme-color('neutral', 100);
      border-color: theme-color('neutral', 400);
    }
    ~ .radio__label::after {
      border-color: theme-color('primary', 500);
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      ~ .radio__label::before {
        background: theme-color('neutral', 300);
        border-color: theme-color('neutral', 500);
      }
    }


    &:checked {
      ~ .radio__label::before {
        background: theme-color('primary', 300);
        border-color: theme-color('primary', 400);
      }

      &:hover,
      &:focus,
      &:active,
      &.active {
        ~ .radio__label::before {
          background: theme-color('primary', 400);
        }
      }
    }
  }
}
