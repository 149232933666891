@use 'sass:color';
@use 'sass:map';


@each $name in $theme-color-names {
  @each $weight in $theme-color-weights {
    .bg-#{$name}-#{$weight} {
      @include bg-theme-color($name, $weight, !important);
    }

    .text-#{$name}-#{$weight} {
      @include text-theme-color($name, $weight, 1, !important);
    }

    .fill-#{$name}-#{$weight} {
      fill: theme-color($name, $weight) !important;
    }

    .stroke-#{$name}-#{$weight} {
      stroke: theme-color($name, $weight) !important;
    }
  }
}


.bg-none { background: none !important; }
.bg-page { background: $color-page !important; }
.bg-surface { background: $color-surface !important; }
