.sharing {
  &__table {
    width: 100%;
    @include margin(-5, right);
    td {
      @include padding(2, y);
      @include padding(5, right);
    }
  }


  &__item {
    &--with-border {
      @include border($pos: bottom);
    }
    td {
      @include padding(5, y);
    }
  }


  &__table td:first-child {
    width: 100%;
  }


  &__title {
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-height: btn-size(height);
  }

  &__new {
    width: 30em;
    display: flex;
    flex-direction: column;
  }


  &__user-attention {
    @keyframes sharing__user-attention {
      0% { background: transparent; }
      10% { background: theme-color('primary', 100); }
      100% { background: transparent; }
    }

    animation: sharing__user-attention 6s;
  }
}
