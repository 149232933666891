@use 'sass:color';
@use 'sass:map';


/// When this mixin is applied to an element, all icon descendants inside that element will use the
/// provided color as fill color.
@mixin set-icon-color($color, $important: null) {
  --icon-color: #{$color $important};
}

/// This mixin marks all descendant svgs as "icons", which means they will use the color set by the
/// `set-icon-color` mixin.
@mixin use-icon-color() {
  path {
    fill: var(--icon-color);
  }
}



@mixin -text-color($color, $icon-color, $important: null) {
  color: $color $important;
  @include set-icon-color($icon-color, $important);

  .fa,
  .fas,
  .far,
  .fal,
  .fab,
  .material-icons {
    color: $icon-color $important;
  }
}


@mixin text-color($color, $important: null) {
  $icon-color: $color;
  @if $color != 'inherit' and lightness($color) < 60% {
    $icon-color: color.scale($color, $alpha: ($icon-opacity - 1) * 100%);
  }
  @include -text-color($color, $icon-color, $important);
}


@mixin text-theme-color($name, $scale, $opacity: 1, $important: null) {
  $color: theme-color($name, $scale, $opacity);
  $icon-color: $color;
  @if $scale >= 300 {
    $icon-color: theme-color($name, $scale, $icon-opacity * $opacity);
  }
  @include -text-color($color, $icon-color, $important);
}


@mixin text-on-bg-theme-color($name, $scale, $important: null) {
  $min-invert-weight: map.get($theme-color-min-invert-weights, $name);
  @if $scale >= $min-invert-weight {
    @include text-theme-color('neutral', 100, 1, $important);
  }
}


@mixin bg-theme-color($name, $scale, $important: null) {
  background: theme-color($name, $scale) $important;
  @include text-on-bg-theme-color($name, $scale, $important);
}
