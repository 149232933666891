$color-bad: theme-color('error', 300);
$color-medium: theme-color('warning', 300);
$color-good: #00ba00;

.signal-strength {
  &__icon {
    &--bad .quality-bad {
      fill: $color-bad;
    }

    &--medium .quality-medium {
      fill: $color-medium;
    }

    &--good .quality-good {
      fill: $color-good;
    }
  }
}
