// This color is designed to be equal neutral-300 ($btn-hover-color) when mixed with white.
$tile-hover-color: rgba(30, 30, 50, .12);

tiles,
tiles-phone {
  flex: 1 0 0;
  position: relative;
}

tiles {
  z-index: z(app, tiles);
}


.tiles {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &__tiles {
    pointer-events: initial;
  }

  &__pointers {
    position: absolute;
    z-index: z(tiles, pointers);
  }
}


.tile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include border($width: $tile-border-width);
  border-radius: $border-radius;

  &--borderless {
    border: 0;
    border-radius: 0;
  }

  &--inactive-selected {
    border-color: theme-color('primary', 300);
  }


  &--clickable {
    @include clickable-image-wrapper('.tile__overflow');
  }

  &--clickable-white {
    cursor: pointer;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      &::after {
        background: $tile-hover-color;
      }
    }
    &:active,
    &.active {
      &::after {
        box-shadow: $btn-active-shadow;
      }
    }
  }


  @include media-desktop {
    &:not(.tile--inactive) {
      z-index: z(tiles, active-tile);
    }
    &--inactive {
      z-index: z(tiles, inactive-tile);
    }
  }


  // There is a bug in Safari that causes the combination of border-radius + overflow:hidden to not
  // work. This intermediate element fixes that.
  &__overflow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $color-surface;
    border-radius: $border-radius;
    // stylelint-disable-next-line property-no-vendor-prefix, value-no-vendor-prefix
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    .tile--borderless > & {
      border-radius: 0;
    }

    .tile--inactive-selected > & {
      background: theme-color('primary', 100);
    }
  }


  &__body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    &--draggable {
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }
  }

  &__catch-events-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }


  &__stats {
    position: absolute;
    top: spacer(2);
    left: spacer(3);
    max-width: calc(100% - #{spacer(5)});
    max-height: calc(100% - #{spacer(4)});
    overflow: auto;
    @include padding(2);
    @include border-round;

    @include text-sm;
    background: $color-overlay;
    @include text-inverted;
    z-index: z(tile, stats);
  }

  &__stats-table {
    td,
    th {
      @include padding(1, x);
    }
  }


  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: z(tile, overlay);
    @include padding(4);
    pointer-events: none;

    font-family: $font-family-base;
    font-size: var(--js-tile-overlay-font-size);
    background: $color-overlay;
    @include text-inverted;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.ng-hide {
      opacity: 0;
    }
    &.ng-hide-add,
    &.ng-hide-remove {
      transition: opacity $transition-duration-medium;
    }


    .tile--inactive & {
      @include padding(2);
    }


    &--primary {
      background: none;

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background: theme-color('primary', 300);
        @include text-inverted;
        opacity: .8;
      }
    }


    &--top {
      height: auto;
      background: theme-color('warning', 300);
      justify-content: flex-start;
      text-align: left;
      @include padding(2);

      .tile--inactive & {
        @include padding(1);
      }
    }
  }


  &__loading {
    width: 2em;
    height: 2em;
    margin-right: .4em;
  }


  &__name {
    flex-grow: 1;
    align-self: stretch;
    display: flex;
    align-items: center;

    @include text-truncate;

    &--center {
      justify-content: center;
    }
  }
}
