@use 'sass:map';


@mixin text-base {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

@mixin text-sm {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
}

@mixin text-lg {
  font-size: $font-size-lg;
  line-height: $line-height-lg;
}


@mixin text-h($h) {
  font-family: $font-family-heading;
  font-size: map.get($h-font-size, $h);
  font-weight: map.get($h-font-weight, $h);
  line-height: map.get($h-line-height, $h);
  letter-spacing: map.get($h-letter-spacing, $h);
  text-transform: map.get($h-text-transform, $h);
}


@mixin h($h) {
  @include text-h($h);

  margin-top: map.get($h-margin-top, $h);
  margin-bottom: map.get($h-margin-bottom, $h);

  &:first-child {
    margin-top: map.get($h-margin-top-first, $h);
  }
}



@mixin p {
  margin-top: 0;
  @include margin(4, bottom);
}
@mixin pp {
  margin-top: 0;
  @include margin(12, bottom);
}


@mixin a-override {
  &,
  &:hover,
  &:focus,
  &:visited {
    @content;
  }
}

@mixin a {
  cursor: pointer;
  background: none;
  border: 0;

  @include a-override {
    @include text-theme-color($colordef-a...);
  }

  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
@mixin a-inverted {
  @include a;
  @include a-override {
    @include text-theme-color($colordef-a-inverted...);
  }
}
@mixin a-plain {
  @include a-override {
    @include text-color(inherit);
    text-decoration: none;
  }
}

@mixin pre {
  @include p;
}


@mixin text-default($important: null) {
  @include text-theme-color($colordef-text..., $important: $important);
}
@mixin text-muted($important: null) {
  @include text-theme-color($colordef-text-muted..., $important: $important);
}
@mixin text-inverted($important: null) {
  @include text-theme-color($colordef-text-inverted..., $important: $important);
  a:not(.btn, .a-plain),
  .a:not(.btn, .a-plain) {
    @include a-inverted;
  }
}
@mixin text-muted-inverted($important: null) {
  @include text-theme-color($colordef-text-muted-inverted..., $important: $important);
}


@mixin text-truncate {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin multiline-truncate($line-count) {
  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  line-clamp: $line-count;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
