.toggle {
  opacity: 0;
  position: absolute;


  &__label {
    position: relative;

    width: 100%;
    margin-bottom: 0;
    height: $toggle-track-height;
    padding-right: $toggle-width + spacer($toggle-margin-right);

    display: flex;
    flex-direction: column;
    justify-content: center;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      right: 0;
      width: $toggle-width;
      height: $toggle-track-height;
      border-radius: calc($toggle-track-height / 2);

      background: theme-color('neutral', 300);
      border: $toggle-track-border solid theme-color('neutral', 300);

      transition-property: background, border;
      transition-duration: $transition-duration-fast;
    }

    &::after {
      content: '';
      position: absolute;
      right: $toggle-track-border + $toggle-track-padding;
      transform: translateX(
        -$toggle-width
        + $toggle-ball-size
        + 2 * ($toggle-track-border + $toggle-track-padding)
      );
      width: $toggle-ball-size;
      height: $toggle-ball-size;
      border-radius: calc($toggle-ball-size / 2);

      background: theme-color('neutral', 200);
      border: $toggle-ball-border solid theme-color('neutral', 200);

      transition-property: background, border, transform, box-shadow;
      transition-duration: $transition-duration-fast;
    }


    &--standalone {
      width: 0;
      padding-right: $toggle-width;
    }

    &--right {
      padding-left: $toggle-width + spacer($toggle-margin-left);
      padding-right: 0;

      &::before {
        left: 0;
        right: auto;
      }
      &::after {
        left: $toggle-width - $toggle-ball-size - ($toggle-track-border + $toggle-track-padding);
        right: auto;
      }
    }
  }


  &:checked {
    ~ .toggle__label {
      &::before {
        background: theme-color('primary', 100);
        border-color: theme-color('primary', 100);
      }
      &::after {
        background: theme-color('neutral', 100);
        border-color: theme-color('neutral', 100);
        transform: translateX(0);
      }
    }
  }


  &:not(:disabled, .disabled) {
    ~ .toggle__label {
      cursor: pointer;

      &::before {
        background: theme-color('neutral', 200);
        border-color: theme-color('neutral', 400);
      }
      &::after {
        background: theme-color('neutral', 100);
        border-color: theme-color('neutral', 400);
      }
    }

    &:hover {
      ~ .toggle__label {
        &::before {
          background: theme-color('neutral', 300);
          border-color: theme-color('neutral', 500);
        }
      }
    }


    &:checked {
      ~ .toggle__label {
        &::before {
          background: theme-color('primary', 300);
          border-color: theme-color('primary', 400);
        }
        &::after {
          background: theme-color('neutral', 100);
          border-color: theme-color('neutral', 400);
        }
      }

      &:hover {
        ~ .toggle__label {
          &::before {
            background: theme-color('primary', 400);
            border-color: theme-color('primary', 500);
          }
        }
      }
    }


    // stylelint-disable no-descending-specificity
    &:active,
    &.active {
      ~ .toggle__label {
        &::before {
          @include shadow(2);
        }
      }
    }
    // stylelint-enable no-descending-specificity
  }
}
